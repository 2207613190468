import React from "react";
import Row from "./Row";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;

  if (
    ownProps.visibility === undefined ||
    typeof ownProps.visibility !== "function"
  ) {
    return { visibile: true };
  } else {
    return {
      visible: ownProps.visibility(formReducer)
    };
  }
};

const ConnectedRow = connect(
  mapStateToProps,
  null
)(Row);

export default ConnectedRow;
