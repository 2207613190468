import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import styles from "./DateInput.module.css";

const DateInput = props => {
  const { id, handleChange, error, handleMount, handleUnmount, style } = props;
  const [value, setValue] = useState(props.value);
  
  const getTodaysDate=()=>{
      const todaysDate= new Date()
      const month= todaysDate.getMonth()>=9?todaysDate.getMonth()+1:`0${todaysDate.getMonth()+1}`
      return `${todaysDate.getFullYear()}-${month}-${todaysDate.getDate()}`
  }
  const handleTodaysDate=(path)=>{
     return path==="applicantInformation.expiring_policydifferentDate"? 
     getTodaysDate()
     : null
  }
  console.log(props.path, handleTodaysDate(props.path))
  useEffect(() => {
    handleMount();
    return () => {
      handleUnmount();
    };
  }, []);

  return (
    <div style={style}>
      <TextField
        autoComplete="off"
        variant="outlined"
        type="date"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{ id: id }}
        id={id}
        name={id}
        onBlur={e => handleChange(id, e.target.value)}
        onChange={e => {
          setValue(e.target.value);
        }}
        inputProps={{ min: handleTodaysDate(props.path) }}
        value={value}
        margin="normal"
        color="primary"
        fullWidth
        error={error && error.value === true}
      />

      {error && error.value === true ? (
        <div className={styles["helpTextContainer"]}>
          {" "}
          <span className={styles.helpText}>{props.error.message}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DateInput;
