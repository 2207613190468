import React from "react";
import Form from "./YogaForm";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;

  if (
    ownProps.visibility === undefined ||
    typeof ownProps.visibility !== "function"
  ) {
    return {
      visible:
        formReducer.values.applicantInformation.modalities.indexOf("Yoga") >= 0,
      values: formReducer.values
    };
  } else {
    return {
      visible:
        formReducer.values.applicantInformation.modalities.indexOf("Yoga") >= 0,
      values: formReducer.values
    };
  }
};

const mapDispatchToProps = dispatch => {
  return {
    displayGenericModal: message => {
      dispatch({ type: "GENERIC_MODAL", message });
    },
    clearForm: message => {
      dispatch({ type: "HANDLE_YOGA", message });
    }
  };
};

const ConnectedForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);

export default ConnectedForm;
