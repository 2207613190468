import { getValue } from "../../utils";
import DateInput from "./DateInput";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;
  if (!ownProps.path) {
    throw new Error("no path", ownProps);
  }
  const valid = getValue(formReducer.valid, ownProps.path);

  return {
    error: {
      value: formReducer.submissionAttempted && !valid,
      message: "* This field is required"
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (path, value) =>
      dispatch({ type: "UPDATE_FORM", path, value }),
    handleMount: () => dispatch({ type: "MOUNTED", path: ownProps.path }),
    handleUnmount: () => dispatch({ type: "UNMOUNTED", path: ownProps.path })
  };
};

const ConnectedDateInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateInput);

export default ConnectedDateInput;
