import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Agreement from "components/Agreement";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import CurrencyInput from "components/CurrencyInput";
import { Select } from "@material-ui/core";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export const AdditionalCoverageQuestion = props => {
  const { coverageName, id, values, handleChange, info, price, type } = props;
  return (
    <>
      <Row>
        <Col xs={12} sm={6}>
          <div>
            <Typography variant={"subtitle1"} align={"left"}>
              <strong> Please consider {coverageName}</strong>
            </Typography>
          </div>

          {typeof info === "string" ? (
            // <Col xs={12} sm={6}>
            <Col xs={12} sm={12}>
              {" "}
              <Typography variant="caption">{info}</Typography>
            </Col>
          ) : (
            info
          )}
        </Col>
      </Row>
      <br />
      { type !== "international" ? 
        <Row>
            <Col xs={12} sm={6}>
            <div>
                <Typography variant={"subtitle1"} align={"left"}>
                <strong>
                    Add {coverageName} coverage to your policy, for an additional $
                    {price}
                </strong>
                </Typography>
            </div>
            </Col>
            <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id={id} path={id} />
            </Col>
        </Row>
        :""}
      <br />
    </>
  );
};
export const UploadQuestion = props => {
  const { info } = props;
  return (
    <Row>
      <Col xs={12} sm={6}>
        <div>
          <Typography variant={"subtitle1"} align={"left"}>
            <strong>{props.question}</strong>
          </Typography>
        </div>

        {typeof info === "string" ? (
          // <Col xs={12} sm={6}>
          <Col xs={12} sm={12}>
            {" "}
            <Typography variant="caption">{info}</Typography>
          </Col>
        ) : (
          info
        )}
      </Col>
      <Col sm={6} xs={12}>
        <FileUploadInput fileCollectionName={props.fileCollectionName} />
      </Col>
    </Row>
  );
};
export const YesNoQuestion = props => {
  return (
    <Row>
      <Col xs={12} sm={6}>
        <div>
          <Typography variant={"subtitle1"} align={"left"}>
            <strong>{props.question}</strong>
          </Typography>
        </div>
        {typeof props.info === "string" ? (
          <Typography variant="caption">{props.info}</Typography>
        ) : (
          props.info
        )}
      </Col>
      <Col sm={3} xs={12}>
        <YesNoInput id={props.id} path={props.path} />
      </Col>
    </Row>
  );
};
export const TextQuestion = props => {
  return (
    <Row>
      <Col xs={12} sm={6}>
        <div>
          <Typography variant={"subtitle1"} align={"left"}>
            <strong>{props.question}</strong>
          </Typography>
        </div>
        {typeof props.info === "string" ? (
          <Typography variant="caption">{props.info}</Typography>
        ) : (
          props.info
        )}
      </Col>
      <Col sm={6} xs={12}>
        <TextInput
          id={props.id}
          path={props.path}
          // persistOnUnmount is forwarded to the TextInup as this is a wrapper to simplify code
          persistOnUnmount={props.persistOnUnmount}
        />
      </Col>
    </Row>
  );
};

export const NumberQuestion = props => {
  return (
    <Row>
      <Col xs={12} sm={6}>
        <div>
          <Typography variant={"subtitle1"} align={"left"}>
            <strong>{props.question}</strong>
          </Typography>
        </div>
        {typeof props.info === "string" ? (
          <Typography variant="caption">{props.info}</Typography>
        ) : (
          props.info
        )}
      </Col>
      <Col sm={6} xs={12}>
        <NumberInput id={props.id} path={props.path} />
      </Col>
    </Row>
  );
};
export const CurrencyQuestion = props => {
  return (
    <Row>
      <Col xs={12} sm={6}>
        <div>
          <Typography variant={"subtitle1"} align={"left"}>
            <strong>{props.question}</strong>
          </Typography>
        </div>
        {typeof props.info === "string" ? (
          <Typography variant="caption">{props.info}</Typography>
        ) : (
          props.info
        )}
      </Col>
      <Col sm={6} xs={12}>
        <CurrencyInput id={props.id} path={props.path} />
      </Col>
    </Row>
  );
};
export const DateQuestion = props => {
  return (
    <Row>
      <Col xs={12} sm={6}>
        <div>
          <Typography variant={"subtitle1"} align={"left"}>
            <strong>{props.question}</strong>
          </Typography>
        </div>
        {typeof props.info === "string" ? (
          <Typography variant="caption">{props.info}</Typography>
        ) : (
          props.info
        )}
      </Col>
      <Col sm={6} xs={12}>
        <DateInput id={props.id} path={props.path} />
      </Col>
    </Row>
  );
};
export const DateQuestionSmall = props => {
    return (
      <Row>
        <Col xs={12} sm={6}>
          <div>
            <Typography variant={"subtitle1"} align={"left"}>
              <strong>{props.question}</strong>
            </Typography>
          </div>
          {typeof props.info === "string" ? (
            <Typography variant="caption">{props.info}</Typography>
          ) : (
            props.info
          )}
        </Col>
        <Col sm={3} xs={6}>
          <DateInput id={props.id} path={props.path} />
        </Col>
        <Col sm={3} xs={6}>
        </Col>
      </Row>
    );
  };
export const MultiSelectQuestion = props => {
  return (
    <Row>
      <Col xs={12} sm={6}>
        <div>
          <Typography variant={"subtitle1"} align={"left"}>
            <strong>{props.question}</strong>
          </Typography>
        </div>
        {typeof props.info === "string" ? (
          <Typography variant="caption">{ ReactHtmlParser(props.info) }</Typography>
        ) : (
          props.info
        )}
      </Col>
      <Col sm={6} xs={12}>
        <SelectInput
          id={props.id}
          path={props.path}
          options={props.options}
          placeholder={props.placeholder}
        />
      </Col>
    </Row>
  );
};

export const AutocompleteQuestion = props => {
  return (
    <Row>
      <Col xs={12} sm={6}>
        <div>
          <Typography variant={"subtitle1"} align={"left"}>
            <strong>{props.question}</strong>
            <br></br>
            {typeof props.info === "string" ? (
            <Typography variant="caption">{ ReactHtmlParser(props.info) }</Typography>
            ) : (
            props.info
            )}
            
          </Typography>
        </div>
      </Col>
      <Col sm={6} xs={12}>
        <Autocomplete
          id={props.id}
          path={props.id}
          options={props.options}
          placeholder={props.placeholder}
        />
      </Col>
    </Row>
  );
};

export const AgreementQuestion = props => {
  return (
    <Row>
      <Col xs={12} sm={6}>
        <div>
          <Typography variant={"subtitle1"} align={"left"}>
            <strong>{props.question}</strong>
          </Typography>
        </div>
        {typeof props.info === "string" ? (
          <Typography variant="caption">{props.info}</Typography>
        ) : (
          props.info
        )}
      </Col>
      <Col sm={3} xs={12}>
        <Agreement id={props.id} path={props.id} />
      </Col>
    </Row>
  );
};

export const InternationalClienteleQuestion = props=>{
    const { id, values, handleChange, info, price, type } = props;
    return(
        <>
        { type === "international" ?
         <Row>
                <Col xs={12} sm={6}>
                <div>
                    <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                    Is your clientele more than 50% outside of Canada
                    </strong>
                    </Typography>
                </div>
                </Col>
                <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id={id} path={id} />
                </Col>
         </Row>
         :""
        }
       <br/>
      </>
    )
}

export const InternationalConsultingChargeQuestion = props => {
    const { coverageName, id, values, handleChange, info, price } = props;
    return(
        <>
        <Row>
        <Col xs={12} sm={6}>
          <div>
            <Typography variant={"subtitle1"} align={"left"}>
              <strong>
                Add {coverageName} coverage to your policy, for an additional $50
                {price}
              </strong>
            </Typography>
          </div>
        </Col>
        <Col sm={3} xs={12} className="yesNoGroup">
          <YesNoInput id={id} path={id} />
        </Col>
      </Row>
      <br/>
      </>
    )
}
