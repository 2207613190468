import values from "./values";
import validation from "./validation";

const disclaimers = {
  previousDecline: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Has complimentary healthcare insurance ever been declined, cancelled or renewal thereof been refused by the insurer?",
    info: ""
  },
  lossesOrClaims: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Have you had any losses / claims in the past three years?",
    info: ""
  },
  knowledge: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Have you had any current or past circumstance that could result in you placing a claim or having a lawsuit being brought against you?",
    info: ""
  },
  agreement: {
    defaultvalue: values.EMPTY_STRING,
    validation: val => {
      return val === "yes";
    },
    question: "Please indicate your agreement:",
    info: ""
  }
};
export default disclaimers;
