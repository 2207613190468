import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import styles from "./TextInput.module.css";

const TextInput = props => {
  const {
    id,
    handleChange,
    error,
    handleMount,
    handleUnmount,
    style,
    placeholder
  } = props;
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    handleMount();
    return () => { //RETURN VALUE WITLL GET TRIGGERED WHEN COMPONENT UNMOUNTS
      handleUnmount();
    };
  }, []);

  return (
    <div style={style} className={styles["wrapper"]}>
      <TextField
        autoComplete="off"
        variant="outlined"
        type="text"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{ id: id }}
        id={id}
        name={id}
        onBlur={e => handleChange(id, e.target.value)}
        onChange={e => {
          setValue(e.target.value);
        }}
        value={value}
        margin="normal"
        color="primary"
        fullWidth
        error={error && error.value === true}
        placeholder={placeholder}
      />

      {error && error.value === true ? (
        <div className={styles["helpTextContainer"]}>
          <span className={styles.helpText}>{props.error.message}</span>
        </div>
      ) : (
          ""
        )}
    </div>
  );
};

export default TextInput;
