import values from "./values";
import validation from "./validation";

const physiotherapy = {
    registration: {
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question: "Registration Number"
    },
    selection: {
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question: "Choose one of the following options:"
    },
    laser: {
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question: "Do you offer any type of Laser therapy (Use a Laser machine)?"
      },
      laserDescribe: {
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question: "Please describe"
      },
      coldOrLowLevelLasers:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"Is this a Cold lasers or low-level lasers machine?"
      },
      ownLaserMachine:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"Do you own the Laser machine?"
      },
      laserMachineDetails:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"Please provide us with the Type of laser machine, make, model, S/N"
      },
      laserMachineAge:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"Please provide us with the age of machine, where was it manufactured & value"
      },
      laserMachineMobile:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"Please advise if this laser equipment will be used in the clinic only or in different locations / mobile"
      },
      laserMachineWaivers:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"Are waivers being signed by each participant?"
      },
      laserMachineMaintenance:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"What is the maintenance for this equipment?"
      },
      laserMachineLeasedOwned:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"Is the equipment leased or owned by you?"
      },
      laserMachineUse:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"What is the machine used for?"
      },
      laserMachineUsedBy:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"Will there be other practitioners operating the equipment?"
      },
      laserMachineTraining:{
          defaultvalue: values.EMPTY_STRING,
          validation: validation.NOT_EMPTY_STRING,
          question:"Are you properly trained for operations of this laser machine?"
      }
};

export default physiotherapy;
