import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
const Col = props => {
  const { lg, md, sm, xl, xs, visible } = props;
  return visible ? (
    <Grid item xs={xs} sm={sm} lg={lg} md={md} xl={xl}>
      {props.children}
    </Grid>
  ) : (
    ""
  );
};

// Col.PropTypes={
//     lg:false,
//     sm:false,
//     md:false,
//     xl:false,
//     xs:false

// }
Col.defaultProps = {
  lg: false,
  sm: false,
  md: false,
  xl: false,
  xs: false,
  visible: true
};

export default Col;
