// Dec 2020: Centralized array contains multiple pricing models, based on effective dates for new vs. renewal business
/**
 * IMPORTANT NOTE: Always put the newest pricing at the end of the array!
 * - The code is doing a very simple run backwards through the array to find a date that applies.
 */
export const pricing = [
 /*     THIS PRICING HAS NOW EXPIRED! It is being left as an example of how to set up multiple pricing structures
    {
        effective: {
            renewal: 0, // historical (as of Dec 1, 2020)
            new: 0, // historical (as of Dec 1, 2020)
        },
        vals: {
            annualRMT: 150,
            sixMonthRMT: 80,
            newGradRMT: 125,
            newGradRMTAcupuncture: 325,
            sixMonthRMTAcupuncture: 180,
            annualRMTAcupuncture: 350,
            annualRMTOsteopathy: 400,
            sixMonthRMTOsteopathy: 0, // not available
            annualRMTOsteopathyAcupuncture: 400,
            sixMonthRMTOsteopathyAcupuncture: 0, // not available
            annualRMTPT1: 250,
            sixMonthRMTPT1: 130,
            annualRMTPT2: 300,
            sixMonthRMTPT2: 155,
            annualCrossfit: 300,
            sixMonthCrossfit: 155,
            addModality: 100,
        },
        rmtBC: {
            annualRMT: 162,
            sixMonthRMT: 0, // not available
            newGradRMT: 135,
            newGradRMTAcupuncture: 351,
            sixMonthRMTAcupuncture: 180,
            annualRMTAcupuncture: 350,
            annualRMTOsteopathy: 432,
            sixMonthRMTOsteopathy: 0, // not available
            annualRMTOsteopathyAcupuncture: 432,
            sixMonthRMTOsteopathyAcupuncture: 0, // not available
            annualRMTPT1: 250,
            sixMonthRMTPT1: 130,
            annualRMTPT2: 300,
            sixMonthRMTPT2: 155,
            annualCrossfit: 300,
            sixMonthCrossfit: 155,
            addModality: 100,
        },
        acupuncturePrices: {
            option1: 325,
            option2: 460,
            option3: 600,
            option4: 425,
            option5: 600,
            option6: 825,
        },
        aromatherapyPrices: {
            premium: 225,
            consulting: 50,
            certification: 50,
            CFA: 225,
            BCAPA: 225,
        },
        estheticsPrices: {
            premium: 225,
        },
        fitnessInstructorPrices: {
            premium: 265,
            paddleboard: 100,
            webconsulting: 50,
        },
        kinesiologyPrices: {
            premium: 240,
        },
        ncPrices: {
            premium: 265,
            cancer: 50,
            bioenergetics: 100,
            cooking: 200,
            webconsulting: 50,
            microscopy: 100,
            ebook: 75,
            CANNP: 200,
            CSNNAA: 200,
        },
        osteopathyPrices: {
            fullPremium: 375,
            studentPremium: 265,
            NMOS: 340,
            Others: 375,
        },
        personalTrainerPrices: {
            standard: 265,
            extended: 300,
            webconsulting: 50,
        },
        physiotherapyPrices: {
            premium: 625,
            option1: 275,
            option2: 450,
            option3: 395,
            option4: 640,
        },
        pilatesPrices: {
            premium: 225,
        },
        psychologistPrices: {
            option1: 370,
            option2: 520,
            optionalPremium: 270,
        },
        reflexologyPrices: {
            premium: 225,
            RACPrices: {
                RACOption1: 145,
                RACOption2: 195,
            },
            certification: 50,
        },
        yogaPrices: {
            premium: 225,
            listedStudio: 210,
            paddleboard: 100,
            ebook: 75,
            consulting: 50,
            therapy: 100,
        },
        optionalCoveragesCharged: {
            consulting: false,
            certification: false,
        },
        catAPrices: {
            premium: 225,
        },
        catBPrices: {
            premium: 265
        },
        catCPrices: {
            "Shiatsu Practitioner": 325,
            "Bioenergetics Intolerance Elimination": 365,
            Electrolysis: 225,
            "Culinary Nutrition Expert": 500,
            "Crossfit trainer": 300,
            "Past Life Regression/Coaching": 375,
            "Soul Coaching/healing": 375,
            "Somatic Experiencing": 375,
            "Art Therapy": 450,
            "Music Therapy": 450,
            "Social Workers": 575,
            Psychotherapists: 575,
        },
        privacyBreach: {
            premium: 120,
        },
        // - - - - - - - - - - - -
        // Provincial Tax Rates
        provincialTax: {
            Ontario: 1.08,
            Quebec: 1.09,
            Manitoba: 1.07,
            Saskatchewan: 1.06,
        },
    },*/
    {
        effective: {
            renewal: 1606798800, // effective December 1, 2021
            new: 1606798800, // effective December 1, 2020
        },
        // Ontario RMT
        vals: {
            annualRMT: 168,
            sixMonthRMT: 90,
            newGradRMT: 140,
            newGradRMTAcupuncture: 350,
            sixMonthRMTAcupuncture: 194,
            annualRMTAcupuncture: 376,
            annualRMTOsteopathy: 428,
            sixMonthRMTOsteopathy: 224,
            annualRMTOsteopathyAcupuncture: 428,
            sixMonthRMTOsteopathyAcupuncture: 224,
            annualRMTPT1: 272,
            sixMonthRMTPT1: 142,
            annualRMTPT2: 324,
            sixMonthRMTPT2: 167,
            annualCrossfit: 324,
            sixMonthCrossfit: 167,
            addModality: 100,
            addModalitySixMonth: 50
        },
        // BC RMT
        rmtBC: {
            annualRMT: 182,
            sixMonthRMT: 97,
            newGradRMT: 152,
            newGradRMTAcupuncture: 0, // not available
            sixMonthRMTAcupuncture: 0, // not available
            annualRMTAcupuncture: 0, // not available
            annualRMTOsteopathy: 463,
            sixMonthRMTOsteopathy: 243,
            annualRMTOsteopathyAcupuncture: 0, // not available
            sixMonthRMTOsteopathyAcupuncture: 0, // not available
            annualRMTPT1: 287,
            sixMonthRMTPT1: 154,
            annualRMTPT2: 339,
            sixMonthRMTPT2: 180,
            annualCrossfit: 339,
            sixMonthCrossfit: 180,
            addModality: 100,
            addModalitySixMonth: 50
        },
        acupuncturePrices: {
            association: {
                prof1mil: 246,
                prof2mil: 394,
                prof5mil: 656,
                profcomm1mil: 356,
                profcomm2mil: 547,
                profcomm5mil: 901,
                profcommpers1mil: 381,
                profcommpers2mil: 572,
                profcommpers5mil: 926,
                rmt1mil: 0,
                rmt2mil: 0,
                rmt5mil: 0
            },
            ontario: {
                prof1mil: 349,
                prof2mil: 494,
                prof5mil: 643,
                profcomm1mil: 456,
                profcomm2mil: 643,
                profcomm5mil: 885,
                profcommpers1mil: 481, //changed this based on IMS-1695
                profcommpers2mil: 668,
                profcommpers5mil: 910,
                rmt1mil: 276,
                rmt2mil: 343,
                rmt5mil: 520
            },
            other: {
                prof1mil: 355,
                prof2mil: 503,
                prof5mil: 656,
                profcomm1mil: 464,
                profcomm2mil: 656,
                profcomm5mil: 901,
                profcommpers1mil: 489,
                profcommpers2mil: 681,
                profcommpers5mil: 926,
                rmt1mil: 0,
                rmt2mil: 0,
                rmt5mil: 0
            },
            webconsulting: 50
        },
        aromatherapyPrices: {
            premium: 253,
            consulting: 50,
            certification: 50,
            CFA: 253,
            BCAPA: 253,
        },
        crossfitPrices: {},
        estheticsPrices: {
            premium: 253,
        },
        fitnessInstructorPrices: {
            premium: 298,
            paddleboard: 100,
            webconsulting: 50,
        },
        kinesiologyPrices: {
            premium: 269,
        },
        ncPrices: {
            premium: 298,
            cancer: 50,
            bioenergetics: 100,
            cooking: 200,
            webconsulting: 50,
            microscopy: 100,
            ebook: 75,
            CANNP: 224,
            CSNNAA: 224,
            fitnessInstructorCoverage: 40,
            personalTrainerCoverageStandard: 40,
            personalTrainerCoverageExtended: 70
        },
        osteopathyPrices: {
            fullPremium: 421,
            studentPremium: 298, 
            // NMOS: 374,
            Others: 421,
        },
        personalTrainerPrices: {
            standard: 298,
            extended: 336,
            webconsulting: 50,
        },
        physiotherapyPrices: {
            premium: 625,
            option1: 369,
            option2: 588,
            option3: 431,
            option4: 703,
        },
        pilatesPrices: {
            premium: 253,
            webconsulting: 50,
        },
        psychologistPrices: {
            option1: 370,
            option2: 520,
            optionalPremium: 270,
        },
        reflexologyPrices: {
            premium: 253,
            RACPrices: {
                RACOption1: 163,
                RACOption2: 219,
            },
            certification: 50,
        },
        yogaPrices: {
            premium: 253,
            listedStudio: 218,
            paddleboard: 100,
            ebook: 75,
            consulting: 50,
            therapy: 100,
        },
        optionalCoveragesCharged: {
            consulting: false,
            certification: false,
        },
        // see modalities.js for list of category A modalities
        catAPrices: {
            premium: 253,
        },
        // see modalities.js for list of category B modalities
        catBPrices: {
            premium: 265
        },
        // see modalities.js for list of category C modalities
        catCPrices: {
            "Shiatsu Practitioner": 325,
            "Bioenergetics Intolerance Elimination": 410,
            Electrolysis: 253,
            "Culinary Nutrition Expert": 561,
            "Crossfit trainer": 336,
            "Past Life Regression/Coaching": 375,
            "Soul Coaching/healing": 375,
            "Somatic Experiencing": 375,
            "Art Therapy": 450,
            "Music Therapy": 450,
            "Social Workers": 575,
            Psychotherapists: 575,
        },
        lifeCoach:{
            premium: 298,
            consulting: 50
        },
        sleepConsultant:{
            premium: 253,
            consulting: 50
        },
        reiki:{
            premium: 253,
            consulting: 50
        },
        privacyBreach: {
            premium: 120,
        },
        // - - - - - - - - - - - -
        // Provincial Tax Rates
        provincialTax: {
            Ontario: 1.08,
            Quebec: 1.09,
            Manitoba: 1.07,
            Saskatchewan: 1.06,
            "Newfoundland and Labrador": 1.15,
        },
    },
];