import React from "react";
import StripeCheckout from "react-stripe-checkout";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormContainer from 'components/OneTimeFormContainer/FormContainer';
import Row from "components/Row";
import Col from "components/Col";
import Card from "components/Card";
import ModalOneTimeStyled from 'components/OneTimeFormContainer/components/ModalOneTime';
import { withStyles } from "@material-ui/core";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Api from "api";
import { conformsTo } from "lodash";

const ButtonStyled = withStyles({
    root: {
        "&.Mui-disabled": {
            background: "#289f9e",
            color: "#FFFFFF",
            opacity: 0.5
        }
    },
})(Button);
class OneTimePayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Amount: 0,
            emailAddress: '',
            showModal: false,
            error: false
        };

    }
    amountCallback = (Amount) => {
        this.setState({ Amount });
    }
    emailCallback = (emailAddress) => {
        this.setState({ emailAddress });
    }
    errorCallback = (error) => {
        this.setState({ error });
    }
    handleClearForm() {
        //CLEAR FORM ON SUBMIT
        this.setState({
            fullName: "",
            phoneNumber: "",
            Amount: 0,
            emailAddress: '',
            message: '',
            title:'',
            error: false
        });
    }

    submitPayment = token => {
        const body = {
            form: {
                paymentOption: {
                    selection: 'One-Time'
                }
            }
        };
        if (token) {
            body["token"] = token;
        }
        body["amount"]=this.state.Amount>0? //handle check for if value is 0.0 or 0
        parseInt(this.state.Amount * 100)
        :0

        if( body["amount"]===0){
            this.setState({ showModal: true, error: true ,message: 'Payment was unsuccessful, please contact us at info@ineedapolicy.com. Thank you !' })
        }else{
            Api.sendForm(body)
            .then(res => {
                // dispatch({ type: "FORM_SUBMISSION_SUCCESS" });
                // history.push({ pathname: "/success", state: getState() });
                // document.body.scrollTop = 0; // For Safari
                // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                this.setState({ showModal: true, message: ' We have successfully received your payment. Please check your email for the payment receipt' });
                this.handleClearForm();
            })
            .catch((error) => {
                console.log("Error ", error)
                this.setState({ showModal: true, message: 'Payment was unsuccessful, please contact us at info@ineedapolicy.com. Thank you !' });
            });
        }
    }

    render() {
        const props = this.props;
        return (
            <Card>
                <Row>
                    <Col xs={9} sm={9}>
                        <Typography variant={"h5"} style={{ color: "#22292f", }}>
                            Payment Information
                        </Typography>
                        <hr />
                        <Row>
                            <FormContainer amountCallback={this.amountCallback}
                                emailCallback={this.emailCallback}
                                errorCallback={this.errorCallback} />
                        </Row>
                        <Row>
                            <StripeCheckout
                                label="Pay now" //Component button text
                                name="ineedapolicy.com" //Modal Header
                                description="Pay for your insurance policy today."
                                panelLabel="Pay now" //Submit button in modal
                                amount={parseFloat(this.state.Amount) * 100} //Amount in cents $9.99
                                token={t => this.submitPayment(t)}
                                stripeKey={process.env.REACT_APP_STRIPE_KEY}
                                className="hhh"
                                currency="CAD"
                                disabled={this.state.error}
                                email={this.state.emailAddress}
                                billingAddress={false}
                            >
                                <ButtonStyled id="pay-now" onClick={props.closeModal} disabled={this.state.error}>
                                    <FontAwesomeIcon icon={faLock} size="xs" pull="left" />
                                    Pay ${this.state.Amount} Now
                                </ButtonStyled>
                            </StripeCheckout>
                            {/*PAYMENT CONFIRMATION MODAL */}
                            {this.state.showModal ? <ModalOneTimeStyled open={this.state.showModal} closeModal={props.closeModal} message={this.state.message} /> : ''}
                        </Row>
                    </Col>
                    <Col xs={3} sm={3}></Col>
                </Row>
            </Card>
        );
    }
}

export default OneTimePayment;