import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { Select } from "@material-ui/core";
import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class KinesiologyForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length-1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].kinesiologyPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].kinesiologyPrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Kinesiology
        </Typography>
        <hr />
        <YesNoQuestion
          id={"kinesiology.registrationyesNo"}
          path={"kinesiology.registrationyesNo"}
          question={"Do you have a Kinesiology registration number?"}
          info={
            "If yes, please provide. If not, please upload your Kinesiology diploma below."
          }
        />
        {values.kinesiology.registrationyesNo === "yes" ? (
          <TextQuestion
            id={"kinesiology.registrationnumber"}
            path={"kinesiology.registrationnumber"}
            question={"Please enter your registration number"}
          />
        ) : values.kinesiology.registrationyesNo === "no" ? (
          <UploadQuestion
            question={"Please upload your Kinesiology diploma"}
            fileCollectionName={"kinesiologyFiles"}
          />
        ) : (
          ""
        )}
        <YesNoQuestion
          id={"kinesiology.laser"}
          path={"kinesiology.laser"}
          question={"Do you offer any type of Laser therapy (Use a Laser machine)?"}
        />
      { values.kinesiology.laser === "yes"
        ? (<YesNoQuestion
          id={"kinesiology.coldOrLowLevelLasers"}
          path={"kinesiology.coldOrLowLevelLasers"}
          question={
          "Is this a Cold lasers or low-level lasers machine?"
          }
        />)
        : null}
        { values.kinesiology.coldOrLowLevelLasers === "no"
        ? (<YesNoQuestion
          id={"kinesiology.ownLaserMachine"}
          path={"kinesiology.ownLaserMachine"}
          question={
          "Do you own the Laser machine?"
          }
        />)
        : null}
        { values.kinesiology.ownLaserMachine === "yes"
            ? (
            <>
            <TextQuestion
                id={"kinesiology.laserMachineDetails"}
                path={"kinesiology.laserMachineDetails"}
                question={
                "Please provide us with the Type of laser machine, make, model, S/N"
                }
            />
            <TextQuestion
                id={"kinesiology.laserMachineAge"}
                path={"kinesiology.laserMachineAge"}
                question={
                "Please provide us with the age of machine, where was it manufactured & value"
                }
            />
            <TextQuestion
                id={"kinesiology.laserMachineMobile"}
                path={"kinesiology.laserMachineMobile"}
                question={
                "Please advise if this laser equipment will be used in the clinic only or in different locations / mobile"
                }
            />
            <YesNoQuestion
                id={"kinesiology.laserMachineWaivers"}
                path={"kinesiology.laserMachineWaivers"}
                question={
                "Are waivers being signed by each participant?"
                }
            />
            <TextQuestion
                id={"kinesiology.laserMachineMaintenance"}
                path={"kinesiology.laserMachineMaintenance"}
                question={
                "What is the maintenance for this equipment?"
                }
            />
            <TextQuestion
                id={"kinesiology.laserMachineLeasedOwned"}
                path={"kinesiology.laserMachineLeasedOwned"}
                question={
                "Is the equipment leased or owned by you?"
                }
            />
            <TextQuestion
                id={"kinesiology.laserMachineUse"}
                path={"kinesiology.laserMachineUse"}
                question={
                "What is the machine used for?"
                }
            />
            <YesNoQuestion
                id={"kinesiology.laserMachineUsedBy"}
                path={"kinesiology.laserMachineUsedBy"}
                question={
                "Will there be other practitioners operating the equipment?"
                }
            />
            <YesNoQuestion
                id={"kinesiology.laserMachineTraining"}
                path={"kinesiology.laserMachineTraining"}
                question={
                "Are you properly trained for operations of this laser machine?"
                }
            />
            
            </>)
        : null}
        {values.kinesiology.coldOrLowLevelLasers === "yes"
        ?  (<UploadQuestion
            question={"Please upload training certificate for the use of the machine"}
            fileCollectionName={"kinesiologyLaserMachineFiles"}
           />)
        :null}
      </Card>
    );
  }
}