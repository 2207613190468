export const NOT_EMPTY_STRING = value => {
  return value !== "";
};
export const NOT_EMPTY_ARRAY = value => {
  return value.length > 0;
};
export const VALID_EMAIL = value => {
  var re = /\S+@\S+\.\S+/;
  return re.test(value);
};
export const OPTIONAL = () => {
  return true;
};
export const VALID_CURRENCY = value =>{
    return new RegExp("^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$","gm").test(value);
}

export default {
  NOT_EMPTY_STRING,
  NOT_EMPTY_ARRAY,
  VALID_EMAIL,
  OPTIONAL,
  VALID_CURRENCY
};
