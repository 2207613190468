import { combineReducers } from "redux";
import formReducer from "./formReducer";
import fileReducer from "./fileReducer";
import modalReducer from "./modalReducer";

export default combineReducers({
  formReducer,
  fileReducer,
  modalReducer
});
