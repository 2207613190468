import values from "./values";
import validation from "./validation";
const reflexology = {
  RAC: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Are you a member of the Reflexology Association of Canada?"

  },
  RACPolicy: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Please choose an RAC coverage option"
  },
  RRCO:{
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Are you a member of RRCO – Reflexology Registration Council of Ontario?"
  },
  certificationyesNo: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Do you offer a Certification program, where you are teaching and certifying others?"

  },
  certificationaddCoverage: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Do you wish to add Certification coverage to your policy?",
    info: "Please Note: You must have a Minimum 3 years of experience in the modality you are teaching and The curriculum must be based on a pre-existing course program"

  }

  // NSARP: {
  //   defaultvalue: values.EMPTY_STRING,
  //   visible: false,
  //   valid: true,
  //   validation: validation.NOT_EMPTY_STRING,
  //   value: values.EMPTY_STRING
  // }
};

export default reflexology;
