import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { Link } from "@material-ui/core";

import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion,
  InternationalClienteleQuestion,
  InternationalConsultingChargeQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

const Redirect = props => {
  const { apply, renew } = props;
  return (
    <div>
      <Typography variant={"subheading2"} align={"left"}>
        {" "}
        <strong>
          {" "}
          To continue with your application proceed to one of the links below{" "}
        </strong>
      </Typography>
      <ul>
        <li>
          {" "}
          <Link href={apply}>New Applicant</Link>
        </li>
        <li>
          {" "}
          <Link href={renew}>Renewal</Link>
        </li>
      </ul>
    </div>
  );
};

export default class NCForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    // if (values.NC.CANNP === "yes") {
    //   this.props.displayGenericModal(
    //     <Redirect
    //       apply={"https://nutritionalinsurance.com/cannp/apply/"}
    //       renew={"https://nutritionalinsurance.com/cannp/renew/"}
    //     />
    //   );
    //   this.props.clearForm();
    // }
    // if (values.NC.CSNNAA === "yes") {
    //   this.props.displayGenericModal(
    //     <Redirect
    //       apply={"https://nutritionalinsurance.com/csnnaa/apply/"}
    //       renew={"https://nutritionalinsurance.com/csnnaa/renew/"}
    //     />
    //   );
    //   this.props.clearForm();
    // }

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].ncPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].ncPrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Nutritional Consultant
        </Typography>
        <hr />

        <YesNoQuestion
          id={"NC.CANNP"}
          path={"NC.CANNP"}
          question={
            "Are you a member of the Canadian Association Natural Nutritional Practitioners (CANNP)?"
          }
        />

        <YesNoQuestion
          id={"NC.CSNNAA"}
          path={"NC.CSNNAA"}
          question={
            "Are you a member of the Canadian School of Natural Nutrition Alumni Association (CSNNAA)?"
          }
        />

        <br />
        <br />
        <Typography variant={"h6"}>
          {" "}
          Please indicate what percentage of each make up your overall business
          as a Nutritional Consultant (up to 100%):
        </Typography>
        <br />
        <NumberQuestion
          id={"NC.consultation"}
          path={"NC.consultation"}
          max={100}
          // percentage={true}

          question={"One-on-One Consultation:*"}
        />

        <NumberQuestion
          id={"NC.supplements"}
          path={"NC.supplements"}
          max={100}
          // percentage={true}

          question={
            "Supplements and product resales: (Items you buy at wholesale without tampering or relabeling and resell to your clients)"
          }
        />

        <NumberQuestion
          id={"NC.detox"}
          path={"NC.detox"}
          max={100}
          // percentage={true}

          question={
            "Meal plans/detoxification plans/Personalized Wellness plans:"
          }
        />

        <NumberQuestion
          id={"NC.speech"}
          path={"NC.speech"}
          max={100}
          // percentage={true}

          question={"Group seminars/public speaking:"}
        />

        <NumberQuestion
          id={"NC.onlinecouse"}
          path={"NC.onlinecouse"}
          question={"Online courses (Detox courses, cooking, etc.)"}
        />

        <NumberQuestion
          id={"NC.teaching"}
          path={"NC.teaching"}
          question={
            "Teaching (are you certifying others to be a nutritional consultant)"
          }
        />

        <NumberQuestion
          id={"NC.resale"}
          path={"NC.resale"}
          question={"Book Resales (books you have not written):"}
        />

        <NumberQuestion
          id={"NC.web"}
          path={"NC.web"}
          question={"How much of your business is web-based?"}
        />

        <YesNoQuestion
          id={"NC.supplementsResale"}
          path={"NC.supplementsResale"}
          question={
            "Do you purchase Supplements and/or other products in bulk and repackage them to sell to your clients?*"
          }
        />

        <br />
        <br />
        <Typography variant={"h6"}> Optional coverage</Typography>
        <br />

        <YesNoQuestion
          id={"NC.canceryesNo"}
          path={"NC.canceryesNo"}
          question={"Are you a Certified Cancer Coach?"}
        // info={
        //   "Please Note: You must include a copy of your certificate of training from the Canadian Cancer Association, if you have not already done so."
        // }
        />

        {values.NC.canceryesNo === "yes" ? (
          <AdditionalCoverageQuestion
            id="NC.canceraddCoverage"
            path="NC.canceraddCoverage"
            question="Please Note: You must include a copy of your certificate of training from the Canadian Cancer Association, if you have not already done so."
            info={
              "Please Note: You must include a copy of your certificate of training from the Canadian Cancer Association, if you have not already done so."
            }
            coverageName="Certified Cancer Coach"
            price={thisPricing.cancer}
          />
        ) : (
            ""
          )}

        <YesNoQuestion
          id={"NC.bioenergeticsyesNo"}
          path={"NC.bioenergeticsyesNo"}
          question={"Do you Offer Bioenergetics Intolerance Elimination?"}
        // info={
        //   "Please note your practice must include client signed waivers, health declaration forms if using BIE machine, maintenance must be maintained as per manufactures guidelines."
        // }
        />
        {values.NC.bioenergeticsyesNo === "yes" ? (
          <AdditionalCoverageQuestion
            id="NC.bioenergeticsaddCoverage"
            path="NC.bioenergeticsaddCoverage"
            info={
              "Please note your practice must include client signed waivers, health declaration forms if using BIE machine, maintenance must be maintained as per manufactures guidelines."
            }
            coverageName="Bioenergetics Intolerance Elimination"
            price={thisPricing.bioenergetics}
          />
        ) : (
            ""
          )}

        <YesNoQuestion
          id={"NC.cookingyesNo"}
          path={"NC.cookingyesNo"}
          question={
            "Do you offer Classes where the participants are preparing and cooking, or do you provide catering services? "
          }
        // info={
        //   <Typography variant="caption">
        //     This coverage enhancement includes the following:
        //     <br />- Up to $10,000 in annual receipts for catering and /
        //     or food sales
        //     <br />- Cooking classes included where participants are
        //     preparing the meals
        //     <br />- Cooking demonstrations / catering for small groups
        //     or private parties
        //   </Typography>
        // }
        />

        {values.NC.cookingyesNo === "yes" ? (
          <AdditionalCoverageQuestion
            id="NC.cookingaddCoverage"
            path="NC.cookingaddCoverage"
            info={
              <Typography variant="caption">
                This coverage enhancement includes the following:
                <br />- Up to $10,000 in annual receipts for catering and / or
                food sales
                <br />- Cooking classes included where participants are
                preparing the meals
                <br />- Cooking demonstrations / catering for small groups or
                private parties
              </Typography>
            }
            coverageName="Culinary Extension"
            price={
              values.NC.CANNP === "yes" || values.NC.CSNNAA === "yes" ? 50 : 200
            }
          />
        ) : (
            ""
          )}

        <YesNoQuestion
          id={"NC.consultingyesNo"}
          path={"NC.consultingyesNo"}
          question={
            "Do you consult or offer programs with Clients via the internet?"
          }
          info={
            "If you consult with clients via the internet (website, social media), international Telephone call, Skype, or Face Time you may purchase coverage for International Web Based consulting"
          }
        />

        {values.NC.consultingyesNo === "yes" ? (
          <>
            <AdditionalCoverageQuestion
              coverageName="International Web based extension"
              id="NC.consultingaddCoverage"
              path="NC.consultingaddCoverage"
              value={values.NC.consultingaddCoverage}
              type="international"
              info={
                "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
                advise, or information, where you cannot control whether it’s a client within Canada or outside of\
                Canada, viewing your advice or content.\
                If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
                Please consider adding the International Web Based Extension to your policy.\
                Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
                must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
                client prior to a session."
              }
              price={thisPricing.webconsulting}
            />
           { values.NC.consultingyesNo === "yes" ?
                (
                <>
                <InternationalClienteleQuestion
                    id="NC.consultingClientele"
                    type="international"
                />
                { values.NC.consultingClientele === "yes" ?
                       <strong>Please complete the form and one of our Complementary Health Brokers will contact you.</strong>
                       :  <InternationalConsultingChargeQuestion
                               id="NC.consultingCharge"
                           />
                }
                </>
                )
                :""
            }
          </>
        ) : (
            ""
          )}

        <YesNoQuestion
          id={"NC.microscopyyesNo"}
          path={"NC.microscopyyesNo"}
          question={"Do you offer Live Blood Cell Microscopy?"}
        // info={
        //   "Please Note: You must include a copy of your certificate of training, if you have not already done so."
        // }
        />

        {values.NC.microscopyyesNo === "yes" ? (
          <>
            <AdditionalCoverageQuestion
              coverageName="Live Blood Cell Microscopy"
              id="NC.microscopyaddCoverage"
              path="NC.microscopyaddCoverage"
              info={
                "Please Note: You must include a copy of your certificate of training, if you have not already done so."
              }
              price={thisPricing.microscopy}
            />
          </>
        ) : (
            ""
          )}

        <YesNoQuestion
          id={"NC.ebookyesNo"}
          path={"NC.ebookyesNo"}
          question={
            "Have you written any books or e-books that were either published or self published?"
          }
          price={thisPricing.ebook}
          info={
            <Typography variant="caption">
              Note: Downloadable books/e-books posted on your website, whether
              for a cost or free should be declared.
            </Typography>
          }
        />

        {values.NC.ebookyesNo === "yes" ? (
          <AdditionalCoverageQuestion
            question="Publisher’s Liability coverage provides the following for an additional $75.00 annuallyIf you have an incidental publishing, such as writing articles in published magazines or online, written a book that has been either published in paper or as an E-book, you should consider Publisher’s liability extension. Whether your e-book is free on your website or you sell them, your exposure is much higher and the individual policy does not cover published writings. Publisher’s liability gives you $25,000 in coverage should someone decide to sue you based on something you published.   Please select to add to your policy.
                  "
            id="NC.ebookaddCoverage"
            path="NC.ebookaddCoverage"
            value={values.NC.ebookaddCoverage}
            info={
              "Publisher’s Liability coverage provides the following for an additional $75.00 annually If you have an incidental publishing, such as writing articles in published magazines or online, written a book that has been either published in paper or as an E-book, you should consider Publisher’s liability extension. Whether your e-book is free on your website or you sell them, your exposure is much higher and the individual policy does not cover published writings. Publisher’s liability gives you $25,000 in coverage should someone decide to sue you based on something you published. Please select to add to your policy."
            }
            coverageName="Publisher’s Liability"
            price={thisPricing.ebook}
          />
        ) : (
            ""
          )}
           {
            values.NC.CSNNAA === "yes" || values.NC.CANNP === "yes" ?
            ( 
                <>
                <YesNoQuestion
                    id={"NC.fitnessInstructorAddCoverage"}
                    path={"NC.fitnessInstructorAddCoverage"}
                    question={
                    "Would you like to add Fitness Instructor coverage?"
                    }
                />
                { values.NC.fitnessInstructorAddCoverage === "yes"
                ? ( <AdditionalCoverageQuestion
                    coverageName="Fitness Instructor"
                    id="NC.fitnessInstructorAddCoverage"
                    path="NC.fitnessInstructorAddCoverage"
                    price={thisPricing.fitnessInstructorCoverage}
                    info="Please Note: A Fitness Instructor under this policy cannot use weights that exceed 20lbs."
                    />)
                : ("")
                }
                <YesNoQuestion
                    id={"NC.personalTrainerAddCoverage"}
                    path={"NC.personalTrainerAddCoverage"}
                    question={
                    "Would you like to add Personal Trainer coverage?"
                    }
                />
                { values.NC.personalTrainerAddCoverage === "yes"
                ? ( <>
                    <MultiSelectQuestion
                    id={"NC.personalTrainerAddCoverageOption"}
                    path={"NC.personalTrainerAddCoverageOption"}
                    question={"Please select one of the following options "}
                    options={[
                        { option: "Standard", value: "standard" },
                        { option: "Extended", value: "extended" },
                    ]}
                    info={"<b>Standard Policy: </b>  Allows no more than 2 clients at a time and weights cannot exceed 80lbs. Signed client waivers required\
                    <br/><b>Extended Policy: </b> Allows no more than 3 clients at a time with no weight restrictions. Signed client waivers required"}  
                    />
                    {/* <AdditionalCoverageQuestion
                    coverageName="Personal Trainer"
                    id="NC.personalTrainerAddCoverage"
                    path="NC.personalTrainerAddCoverage"
                    price={thisPricing.personalTrainerCoverage}
                    info="Please Note: A Personal Trainer under this policy cannot have more than 2 clients at a time and weights cannot exceed 80lb."
                    /> */}
                    </>)
                : ("")
                }
                </>
            )
            :
            ("")
        }
      </Card>
    );
  }
}
