export const modalitiesCatA = ["Chair Massage", "Reiki", "Thai Massage","Qigong(Tai Chi)", "Facial Stretch Therapy (FST)"];

export const modalitiesCatB = [];

export const modalitiesCatC = [
  "Bioenergetics Intolerance Elimination",
  "Electrolysis",
  "Culinary Nutrition Expert",
  "Crossfit trainer",
  "Life Coach",
  "Sleep Consultant"
];

export const modalitiesRTB = ["Other"];

export const categoryOriginal = [
  { option: "Nutritional Consultant", value: "NC" },
  { option: "Pilates", value: "Pilates" },
  {
    option: "Acupuncture/TCM",
    value: "Acupuncture/TCM"
  },
  { option: "Yoga", value: "Yoga" },
  { option: "Personal Trainer", value: "Personal Trainer" },
  { option: "Aromatherapy", value: "Aromatherapy" },
  { option: "Reflexology", value: "Reflexology" },
  { option: "Osteopathy", value: "Osteopathy" },
  { option: "Kinesiology", value: "Kinesiology" },
  { option: "Physiotherapy", value: "Physiotherapy" },
  { option: "Esthetics", value: "Esthetics" },
  { option: "Fitness Instructor", value: "Fitness Instructor" },
  { option: "Registered Massage Therapist", value: "RMT" }
];

export const CategoryA = modalitiesCatA.map(item => {
  return { option: item, value: item };
});

export const CategoryB = modalitiesCatB.map(item => {
  return { option: item, value: item };
});

export const CategoryC = modalitiesCatC.map(item => {
  return { option: item, value: item };
});
export const CategoryRTB = modalitiesRTB.map(item => {
  return { option: item, value: item };
});
