import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import styles from "./NumberInput.module.css";

const NumberInput = props => {
  const { id, handleChange, error, handleMount, handleUnmount } = props;
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    handleMount();
    return () => {
      handleUnmount();
    };
  }, []);

  return (
    <>
      <TextField
        autoComplete="off"
        variant="outlined"
        type="number"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{ id: id }}
        id={id}
        name={id}
        onBlur={e => handleChange(id, e.target.value)}
        onChange={e => {
          setValue(e.target.value);
        }}
        value={value}
        margin="normal"
        color="primary"
        fullWidth
        error={error && error.value === true}
      />

      {error && error.value === true ? (
        <span className={styles.helpText}>{props.error.message}</span>
      ) : (
        ""
      )}
    </>
  );
};

export default NumberInput;
