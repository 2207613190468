import { connect } from "react-redux";
import OneTimePayment from "./OneTimePayment";
import { sendForm } from "../../store/actions";
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        closeModal: () => {
            dispatch({ type: "CLOSE_MODAL" });
        },
        submitApplication: token => {
            dispatch(sendForm(token));
        }
    };
};

export default connect(null, mapDispatchToProps)(OneTimePayment);
