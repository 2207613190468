import { handlePrettyForm } from "utils";
import history from "router/history";
import Api from "api";

export const updateForm = formState => ({
  type: "FORM_STATE_UPDATE",
  formState
});

const getPromises = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({ result: reader.result, name: file.name });
    // reader.error=(error)=>reject(error);
  });
};

export const setFiles = (fileSet, fileGroupName) => {
  return dispatch => {
    dispatch({ type: "PROCESSING_FILES" });

    const promises = Array.from(fileSet).map(x => getPromises(x));

    Promise.all(promises)
      .then(res => {
        let result = res.map(x => x.result);
        let name = res.map(x => x.name);
        // console.log(result);

        dispatch({
          type: "SET_FILES",
          fileSet: result,
          fileGroupName: name,
          groupName: fileGroupName
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
};

const delay = t => new Promise(resolve => setTimeout(resolve, t));

export const sendForm = token => {
  return (dispatch, getState) => {
    dispatch({ type: "SENDING_FORM" });

    let finalPremium = Object.values(getState().formReducer.pricing).reduce(
      (acc, curr) => Math.max(acc, curr.premium || 0),
      0
    );
    let finalOptions = Object.values(getState().formReducer.pricing).reduce(
      (acc, curr) => acc + curr.optional || acc,
      0
    );

    let total =
      (finalPremium + finalOptions) * getState().formReducer.pricing.tax;

    const body = {
      amount: parseInt(total),
      form: getState().formReducer.values,
      prettyForm: handlePrettyForm(
        getState().formReducer.values,
        getState().formReducer.visible
      ),
      uploads: Object.values(getState().fileReducer).reduce((acc, item) => {
        return [...acc, ...item.files];
      }, [])
    };

    if (token) {
      body["token"] = token;
      body["amount"] = parseInt(total * 100);
    }

    Api.sendForm(body)
      .then(res => {
        dispatch({ type: "FORM_SUBMISSION_SUCCESS" });
        history.push({ pathname: "/success", state: getState() });
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      })
      .catch((error) => {
        dispatch({ type: "FORM_SUBMISSION_ERROR" });
      });
  };
};
