import values from "./values";
import validation from "./validation";

const privacyBreach = {
  quote:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you wish to be contacted to receive a quote for Privacy Breach coverage?",
    info: ""
  },
  yesno: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you wish to add Privacy Breach/Cyber Security coverage to your policy?",
    info: "To add Privacy Breach Liability coverage, the additional premium is $120.00 annually and includes $25,000 in First-Party coverage for the following: Remediation expenses to cover various costs (such as notifying customers and employees; and, credit and fraud monitoring expenses); Business interruption to cover loss of income related to the breach, and necessary extra expenses; and, Legal expense coverage for certain legal fees and defence costs incurred as a result of a covered breach. Please select here to add this coverage to your policy."
  },
};
export default privacyBreach;
