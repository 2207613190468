import React from "react";
import LMIlogo from "../../assets/lackner-mclennan-insurance@2x.png";
import YYoga from "../../assets/yyoga.png";
import ModoYoga from "../../assets/ModoYoga-logo-2018-full-red.jpg";
import CANNP from "../../assets/CANNP larger .png";
import RAC from "../../assets/RAC.png";
import CSNNAA from "../../assets/csnnalumni-logo-mech.jpg";
import CFA from "../../assets/cfa.png";
import CAPA from "../../assets/Image 1@2x.png";

export default class ImageGallery extends React.PureComponent {
  render() {
    const { values } = this.props;

    return (
      <>
        {values.yoga.yyoga === "yes" ? (
          <img src={YYoga} className="lmi-logo" />
        ) : (
          ""
        )}
        {values.yoga.modo === "yes" ? (
          <img src={ModoYoga} className="lmi-logo" />
        ) : (
          ""
        )}

        {values.aromatherapy.CFA === "yes" ? (
          <img src={CFA} className="lmi-logo" />
        ) : (
          ""
        )}
        {values.aromatherapy.BCAPA === "yes" ? (
          <img src={CAPA} className="lmi-logo" />
        ) : (
          ""
        )}
      </>
    );
  }
}
