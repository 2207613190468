import React from 'react';
import {
    TextField, withStyles
} from '@material-ui/core';

const TextFieldStyled = withStyles({
    root: {
        '& p': {
            color: "#22292f"
        },
        '& .MuiInputAdornment-root > p': {
            color: "#000000"
        }
    }

})(TextField)

const InputField = (props) => {
    return (
        <TextFieldStyled
            d="outlined-basic"
            variant="outlined"
            value={props.value}
            name={props.name}
            id={props.name}
            onChange={props.onChange}
            placeholder={props.placeholder}
            helperText={props.helperText}
            error={props.error}
            InputProps={props.InputProps}
            required
            fullWidth
            margin="normal" />
    )
};

export default InputField;