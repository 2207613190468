import MockAPI from "./mock";
import RealAPI from "./server";
import TestAPI from "./test";

let Api;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  console.log("DEV");
  Api = TestAPI;
} else {
  console.log("PROD");

  Api = RealAPI;
}

export default Api;
