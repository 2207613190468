import React, { useEffect } from "react";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import styles from "./Autocomplete.module.css";

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;
  /* */
  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput + " " + styles.input
        },
        ...InputProps,
        style: {
          boxSizing: "border-box",
          display: "flex",
          flexWrap: "wrap"
        }
      }}
      {...other}
      variant="outlined"
    />
  );
}

renderInput.propTypes = {
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object
};

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.option) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.option}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {suggestion.option}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number
  ]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired
  }).isRequired
};

function getSuggestions(optionList, value, { showEmpty = true } = {}) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : optionList.filter(suggestion => {
      const keep =
        count < 26 &&
        suggestion.option.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });
}

const DownshiftMultiple = props => {
  const { id, classes, error, value, handleMount, handleUnmount } = props;
  const [inputValue, setInputValue] = React.useState("");
  const [focused, setFocused] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(value || []);
  const isError = error && error.value === true;

  useEffect(() => {
    handleMount();
    return () => {
      handleUnmount();
    };
  }, []);

  if (selectedItem !== value) {
    setSelectedItem(value);
  }

  function handleKeyDown(event) {
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
      props.handleChange(id, selectedItem.slice(0, selectedItem.length - 1));
    }
  }

  function handleInputChange(event) {
    setFocused(true);

    setInputValue(event.target.value);
  }

  function handleChange(item) {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue("");
    setSelectedItem(newSelectedItem);
    setFocused(false);
    props.handleChange(id, newSelectedItem);
  }

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
    props.handleChange(id, newSelectedItem);
  };

  return (
    <>
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex
        }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder: selectedItem.length ? "" : props.placeholder
          });
          return (
            <div className={classes.container}>
              {renderInput({
                classes,
                InputLabelProps: getLabelProps(),
                style: { width: "100%" },

                InputProps: {
                  startAdornment: selectedItem.map(item => (
                    <Chip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      className={classes.chip}
                      style={{ margin: "10px" }}
                      onDelete={handleDelete(item)}
                    />
                  )),
                  onBlur: event => {
                    setFocused(false);

                    onBlur(event); // Execute downshift onblur
                  },
                  onChange: event => {
                    handleInputChange(event);
                    onChange(event);
                  },
                  onFocus: e => {
                    setFocused(true);
                    // onFocus(e);
                  },
                  error: isError
                },
                inputProps
              })}

              {focused ? (
                <div style={{ position: "relative" }}>
                  <Paper
                    className={classes.paper}
                    square
                    style={{
                      position: "absolute",
                      zIndex: "3",
                      width: "100%"
                    }}
                  >
                    {getSuggestions(props.options, inputValue2).map(
                      (suggestion, index) =>{
                        return renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion.value }),
                            highlightedIndex,
                            selectedItem: selectedItem2
                          })
                      }
                    )}
                  </Paper>
                </div>
              ) : null}
            </div>
          );
        }}
      </Downshift>

      {isError === true ? (
        <span className={styles.helpText}>{props.error.message}</span>
      ) : (
          ""
        )}
    </>
  );
};

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired
};

let popperNode;

const MaterialMultiSelect = props => {
  const classes = {};

  return (
    <div className={classes.root}>
      <DownshiftMultiple classes={classes} {...props} />
    </div>
  );
};

export default React.memo(MaterialMultiSelect);
