import values from "./values";
import validation from "./validation";
const crossfit = {
  studio: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Are you working at a CrossFit Studio?"
  },
  cf1: {
    defaultValue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "If your CF1 Certification is over 2 years old, have you renewed your CF1 Certification?"
  }
};

export default crossfit;
