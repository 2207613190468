import { createMuiTheme } from "@material-ui/core";
import green from "@material-ui/core/colors/green";

const Theme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    palette: {
      primary: {
        main: "#444444"
      }
    },
    MuiButtonBase: {
      // The properties to apply
      disableRipple: false // No more ripple, on the whole application 💣!
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: '"#03b3e1"'
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#03b3e1",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "rgba(0, 0, 0, 0.23)"
          }
        },
        "&$focused $notchedOutline": {
          borderColor: "#03b3e1"
          // borderWidth: 1,
        }
      }
    },
    MuiRadio: {
      root: {
        // color: green[400],
        "&$checked": {
          color: "#03b3e1"
        }
      },
      checked: {}
    },
    MuiTypography: {
      h6: {
        color: "#22292f"
      },
      h5: {
        color: "#22292f"
      },
      subheading2: {
        marginRight: 25,
        color: "#444444",
        textAlign: "left"
      },
      subtitle1: {
        color: "#444444",
        marginRight: 30
      }
    },
    MuiButton: {
      text: {
        background: "#289f9e",
        color: "white",
        "&:hover": {
          background: "#289f9e",
          color: "white"
        }
      },
      "&$disabled": {
        background: "#d8d8d8",
        color: "#f3f3f3"
      }
    },
    MuiInputLabel: {
      root: {
        background: "#FFFFFF"
      }
    }
  }
});

export default Theme;
