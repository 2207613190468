import acupuncture from "./acupuncture";
import aromatherapy from "./aromatherapy";
import clientInformation from "./clientInformation";
import disclaimers from "./disclaimers";
import fitnessInstructor from "./fitnessInstructor";
import kinesiology from "./kinesiology";
import nc from "./nc";
import osteopathy from "./osteopathy";
import paymentOption from "./paymentOption";
import personalTrainer from "./personalTrainer";
import physiotherapy from "./physiotherapy";
import reflexology from "./reflexology";
import rmt from "./rmt";
import yoga from "./yoga";
import pilates from "./pilates";
import psychologist from "./psychologist";
import esthetics from "./esthetics";
import privacyBreach from "./privacyBreach";
import crossfit from "./crossfit";
import lifeCoach from "./lifeCoach";
import sleepConsultant from "./sleepConsultant";
import reiki from "./reiki"
const form = {
  aromatherapy,
  fitnessInstructor,
  kinesiology,
  applicantInformation: clientInformation,
  disclaimers,
  NC: nc,
  osteopathy,
  paymentOption,
  personalTrainer,
  physiotherapy,
  reflexology,
  RMT: rmt,
  yoga,
  pilates,
  crossfit,
  psychologist,
  acupuncture,
  esthetics,
  lifeCoach,
  sleepConsultant,
  reiki,
  privacyBreach

};


export default form;
