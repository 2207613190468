import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "./store";
import CircularProgress from "@material-ui/core/CircularProgress";
import Router from "./router";
import Theme from "./components/Theme";

const App = () => {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => setLoaded(true), []);
    return loaded ? (
        <div className="fadeinUp">
            <Provider store={store}>
                <MuiThemeProvider theme={Theme}>
                    <Router />
                </MuiThemeProvider>
            </Provider>
        </div>
    ) : (
            <CircularProgress />
        );
};
/*
In order to prevent clickjacking
*/
const UnsecuredPage = () => (
  <div>
    <h1 style={{color: 'red'}}>If you see this page, App link you have clicked on is unsecure, please do not proceed</h1>
    <h2>Please send us email at __________ with the reference of the application from where you clicked this link.</h2>
    <h2>Click <a style ={{textDecoration: 'none', color: 'red'}} href={window.self.location.href} title='I Need A Policy' target='blank'>here</a> to access Apply.ineedapolicy.com safely.</h2>
  </div>
);

if(window.self === window.top) {
    ReactDOM.render(<App />, document.getElementById("root"));
} else{
    ReactDOM.render(<UnsecuredPage />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
