import React from "react";
import Grid from "@material-ui/core/Grid";

const Row = props => {
  const { visible } = props;
  return visible ? (
    <Grid
      container
      spacing={0}
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      {props.children}
    </Grid>
  ) : (
    ""
  );
};

Row.defaultProps = {
  visible: true
};

export default Row;
