import React from "react";
import Payment from "./Payment";
import { connect } from "react-redux";
import { getValue } from "../../utils";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;
  const valid = getValue(formReducer.valid, ownProps.path);

  const { pricing } = formReducer;

  let finalPremium = Object.values(pricing).reduce(
    (acc, curr) => Math.max(acc, curr.premium || 0),
    0
  );
  let finalOptions = Object.values(pricing).reduce(
    (acc, curr) => acc + curr.optional || acc,
    0
  );

  let totalstripe = (finalPremium + finalOptions) * pricing.tax;

  return {
    totalstripe,
    finalPremium,
    finalOptions,
    error: {
      value: formReducer.submissionAttempted && !valid,
      message: "* This field is required"
    },
    values: formReducer.values
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (path, value) =>
      dispatch({ type: "UPDATE_FORM", path, value }),
    handleMount: () => dispatch({ type: "MOUNTED", path: ownProps.path }),
    handleUnmount: () => dispatch({ type: "UNMOUNTED", path: ownProps.path })
  };
};

const ConnectedPayment = connect(mapStateToProps, mapDispatchToProps)(Payment);

export default ConnectedPayment;
