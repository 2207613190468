import values from "./values";
import validation from "./validation";

const acupuncture = {
  selectedOption: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please choose from the following options:",
    info: "(Each options with 5,000,000 aggregate a year)"
  },
  servicesOutsideCanada: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you provide services outside of Canada?",
    info:
      "(Please note this policy will only provide coverage while practicing within Canada)"
  },
  rmtPolicy: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you currently have an active Registered Massage Therapist policy with LMI Canada?",
    info: ""
  },
  associationMember: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are you a member of an Association?",
    info: ""
  },
  associationOption: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Please choose one of the Acupuncture Associations you are a current member of:",
    info: "We have a special discount rate for the following Associations, you must be an active member with one of these associations in order to qualify for the discount."
  },
  activeMember: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are you an active member, in good standing with the CTCMPAO / CTCMA / CAAA / CTCMPANL?",
    info: ""
  },
  activeMemberNumber: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please provide your registration number?",
    info: ""
  },
  activeMemberApplying: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are you in the proccess of applying membership with the CTCMPAO / CTCMA / CAAA / CTCMPANL?",
    info: ""
  },
  currentPolicy: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you currently have Acupuncture/TCM Professional Liability Insurance?",
    info: ""
  },
  currentPolicyName: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Company Name",
    info: ""
  },
  currentPolicyNumber: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Policy Number",
    info: ""
  },
  currentPolicyRetroactive: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "What is your retroactive date",
    info: ""
  },
  currentPolicyExpiry: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "What is your expiry date?",
    info: ""
  },
  sellProducts: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you sell products?",
    info: ""
  },
  sellProductsDescription: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Describe the products you sell:",
    info: ""
  },
  manufactureProducts: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you manufacture products?",
    info: ""
  },
  manufactureProductsDescription: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Describe the products you manufacture:",
    info: ""
  },
  manufactureProductsToBeSold:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you sell these products either online or in a store?",
    info: ""
  },
  TCMPill: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you offer TCM / Herbal Medicine in a pill form?",
    info: ""
  },
  TCMPillManufacture: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you manufacture / repack / relabel the pills?",
    info: ""
  },
  TCMPillManufactureApproved: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are the Pills being Manufactured in a Canadian Goverenment approved Facility",
    info: ""
  },
  TCMPillManufactureHerbs: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are the Herbs being tested prior to being composed in the pill form",
    info: ""
  },
  TCMPillManufatureCanada: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are the Herbs purchased in Canada or by a Canadian certified supplier",
    info: ""
  },
  laser: {
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question: "Do you offer any type of Laser therapy (Use a Laser machine)?"
    },
    laserDescribe: {
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question: "Please describe"
    },
    coldOrLowLevelLasers:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Is this a Cold lasers or low-level lasers machine?"
    },
    ownLaserMachine:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Do you own the Laser machine?"
    },
    laserMachineDetails:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Please provide us with the Type of laser machine, make, model, S/N"
    },
    laserMachineAge:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Please provide us with the age of machine, where was it manufactured & value"
    },
    laserMachineMobile:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Please advise if this laser equipment will be used in the clinic only or in different locations / mobile"
    },
    laserMachineWaivers:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Are waivers being signed by each participant?"
    },
    laserMachineMaintenance:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"What is the maintenance for this equipment?"
    },
    laserMachineLeasedOwned:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Is the equipment leased or owned by you?"
    },
    laserMachineUse:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"What is the machine used for?"
    },
    laserMachineUsedBy:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Will there be other practitioners operating the equipment?"
    },
    laserMachineTraining:{
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question:"Are you properly trained for operations of this laser machine?"
    }

};
export default acupuncture;
