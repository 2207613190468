import React from "react";
import history from "./history";

import { Router, Switch, Route } from "react-router-dom";

import Home from "../screens/Home";
import SimpleModal from "../screens/Modal";
import Success from "../screens/Success";
import OneTimePayment from '../screens/OneTimePayment';

const MyRouter = ({ showModal }) => (
  <>
    <Router history={history}>
      <Switch>
        {/* This route is specific for a OneTimePayment */}
        <Route path="/pay-now" exact render={props => <OneTimePayment {...props} />} />
        <Route path="/success" render={props => <Success {...props} />} />
        <Route
          render={props => (
            <>
              <Home {...props} />
              <SimpleModal {...props} />
            </>
          )}
        />
      </Switch>
    </Router>
  </>
);

export default MyRouter;
