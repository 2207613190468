import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { Select } from "@material-ui/core";
import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion,
  InternationalClienteleQuestion,
  InternationalConsultingChargeQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class FiForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length-1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].acupuncturePrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].acupuncturePrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Pilates
        </Typography>
        <hr />

        <YesNoQuestion
          id={"pilates.equipment"}
          path={"pilates.equipment"}
          question={"Do you teach Equipment Pilates?"}
        />
        {values.pilates.equipment === "yes"
          ? "Please be advised if you are using the Cadillac  it must be one on one training.  "
          : null}
          
        {/* TRX changes */}
        <YesNoQuestion
          id={"pilates.trx"}
          path={"pilates.trx"}
          question={"Do you use TRX or Ropes in your practice?"}
        />
        {
          values.pilates.trx === "yes"
          ? (
            <>
              <div>IMPORTANT EXCLUSION: Please note this policy prohibits full body suspension.  If you wish to proceed please confirm you do not allow clients to be fully suspended while using the ropes or TRX and you understand that in the event of an accident due to full body suspension, there is no coverage under this policy.</div>
              <YesNoQuestion
                id={"pilates.trxSuspension"}
                path={"pilates.trxSuspension"}
                question={"Do you wish to proceed?"}
              />
              {values.pilates.trxSuspension==="no" 
                ? "Unfortunately, our program does not allow full body suspension, we are happy to find an alternative policy for you. Please complete the form and one of our Complementary Health Brokers will contact you."
                : null}
            </>
          )
          : null
        }
         <AdditionalCoverageQuestion
            coverageName="International Web based extension"
            id="pilates.consultingaddCoverage"
            path="pilates.consultingaddCoverage"
            type="international"
            info={
                "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
                advise, or information, where you cannot control whether it’s a client within Canada or outside of\
                Canada, viewing your advice or content.\
                If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
                Please consider adding the International Web Based Extension to your policy.\
                Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
                must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
                client prior to a session."
            }
            price={thisPricing.webconsulting}
          />
        <InternationalClienteleQuestion
              id="pilates.consultingClientele"
             type="international"
       />
        { values.pilates.consultingClientele === "yes"?
        <strong>Please complete the form and one of our Complementary Health Brokers will contact you.</strong>
        :  <InternationalConsultingChargeQuestion
                id="pilates.consultingCharge"
            />
                    
        }
              
      </Card>
    );
  }
}
