import React from "react";
import Form from "./AromatherapyForm";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;

  return {
    visible:
      formReducer.values.applicantInformation.modalities.indexOf(
        "Aromatherapy"
      ) >= 0,
    values: formReducer.values
  };
};

const ConnectedForm = connect(
  mapStateToProps,
  null
)(Form);

export default ConnectedForm;
