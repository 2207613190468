import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import { Select } from "@material-ui/core";
import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class OsteopathyForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    // if (values.osteopathy.becomingMember === "yes") {
    //   this.props.displayGenericModal(
    //     "Please get in contact with NMOS to apply for this\
    //   modality, they will provide you with instructions on\
    //   completeing your application."
    //   );
    //   this.props.clearForm();
    // }

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].osteopathyPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].osteopathyPrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card style={{ marginTop: "20px" }}>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Osteopathy
        </Typography>
        <hr />

        <YesNoQuestion
          id={"osteopathy.becomingMember"}
          path={"osteopathy.becomingMember"}
          value={values.osteopathy.becomingMember}
          question={
            "Are you a member of an Association?"
          }
        />
        {values.osteopathy.becomingMember === 'yes' ?

          (<MultiSelectQuestion
            path={"osteopathy.associationPicker"}
            id={"osteopathy.associationPicker"}
            question={"Please choose one of the  Osteopathy Association, you are a current member of"}
            options={[
              {
                option:
                  "Ontario Association of Osteopathic Manual practitioners (OAO)",
                value: "OAO"
              },
              {
                option: "Ontario Osteopathic Association (OOA)",
                value: "OOA"
              },
              {
                option:
                  "Ontario Osteopathic and Alternative Medicine Association (OOAMA)",
                value: "OOAMA"
              },
              {
                option: "Canadian Osteopathic Association (COA)",
                value: "COA"
              },
              {
                option:
                  "Alberta Association of Osteopathic Manual Therapists (AAOMT)",
                value: "AAOMT"
              },
              {
                option: "Alberta Osteopathic Association (AOA)",
                value: "AOA"
              },
              {
                option:
                  "Canadian Massage & Manual Osteopathic Therapists Association (CMMOTA)",
                value: "CMMOTA"
              },
              {
                option: "Manual Osteopathy Association of Manitoba Inc. (MOA)",
                value: "MOA"
              },
            //   {
            //     option: "National Manual Osteopathic Society (NMOS)",
            //     value: "NMOS"
            //   }, Removed as of June 2023
              {
                option:
                  "The British Columbia Association of Osteopathic Manual Practitioners (BCAOMP)",
                value: "BCAOMP"
              },
              {
                option: "Saskatchewan Association of Osteopaths (SAO)",
                value: "SAO"
              },
              {
                option: "Other",
                value: "other"
              }
            ]}
          />) : ("")}

        {values.osteopathy.associationPicker === "other" ? (
          <TextQuestion
            question={"Please provide the association name"}
            id={"osteopathy.otherAssociation"}
            path={"osteopathy.otherAssociation"}
          />
        ) : (
            ""
          )}

        <YesNoQuestion
          id={"osteopathy.student"}
          path={"osteopathy.student"}
          value={values.osteopathy.student}
          question={"Are you an Osteopathy student?"}
        />

        {values.osteopathy.student === "yes" ? (
          <YesNoQuestion
            id={"osteopathy.completedStudentyesNo"}
            path={"osteopathy.completedStudentyesNo"}
            question={
              "Have you completed all the courses in your program, and are going onto your thesis/practium?"
            }
            info={
              "If you've completed your in class program or going into your last year, you are no longer eligible for the Osteopathy Student rate, you will be charged the full $" + thisPricing.fullPremium.toFixed(2) + " premium."
            }
          />
        ) : (
            ""
          )}

        {values.osteopathy.completedStudentyesNo === "no" ? (
          <UploadQuestion
            id={"osteopathy.transcripts"}
            path={"osteopathy.transcripts"}
            value={values.osteopathy.transcripts}
            question={"Please upload your current school transcripts."}
            fileCollectionName={"osteopathyFiles"}
          />
        ) : (
            ""
          )}

        {values.osteopathy.completedStudentyesNo === "yes" ? (
          <Row>
            <Typography variant="caption">
              {" "}
              Please note you are no longer eligible for the Osteopathy Student
              rate, you will be charged the full ${thisPricing.fullPremium.toFixed(2)} premium
            </Typography>
          </Row>
        ) : (
            ""
          )}
         <YesNoQuestion
          id={"osteopathy.laser"}
          path={"osteopathy.laser"}
          question={"Do you offer any type of Laser therapy (Use a Laser machine)?"}
        />
      { values.osteopathy.laser === "yes"
        ? (<YesNoQuestion
          id={"osteopathy.coldOrLowLevelLasers"}
          path={"osteopathy.coldOrLowLevelLasers"}
          question={
          "Is this a Cold lasers or low-level lasers machine?"
          }
        />)
        : null}
        { values.osteopathy.coldOrLowLevelLasers === "no"
        ? (<YesNoQuestion
          id={"osteopathy.ownLaserMachine"}
          path={"osteopathy.ownLaserMachine"}
          question={
          "Do you own the Laser machine?"
          }
        />)
        : null}
        { values.osteopathy.ownLaserMachine === "yes"
            ? (
            <>
            <TextQuestion
                id={"osteopathy.laserMachineDetails"}
                path={"osteopathy.laserMachineDetails"}
                question={
                "Please provide us with the Type of laser machine, make, model, S/N"
                }
            />
            <TextQuestion
                id={"osteopathy.laserMachineAge"}
                path={"osteopathy.laserMachineAge"}
                question={
                "Please provide us with the age of machine, where was it manufactured & value"
                }
            />
            <TextQuestion
                id={"osteopathy.laserMachineMobile"}
                path={"osteopathy.laserMachineMobile"}
                question={
                "Please advise if this laser equipment will be used in the clinic only or in different locations / mobile"
                }
            />
            <YesNoQuestion
                id={"osteopathy.laserMachineWaivers"}
                path={"osteopathy.laserMachineWaivers"}
                question={
                "Are waivers being signed by each participant?"
                }
            />
            <TextQuestion
                id={"osteopathy.laserMachineMaintenance"}
                path={"osteopathy.laserMachineMaintenance"}
                question={
                "What is the maintenance for this equipment?"
                }
            />
            <TextQuestion
                id={"osteopathy.laserMachineLeasedOwned"}
                path={"osteopathy.laserMachineLeasedOwned"}
                question={
                "Is the equipment leased or owned by you?"
                }
            />
            <TextQuestion
                id={"osteopathy.laserMachineUse"}
                path={"osteopathy.laserMachineUse"}
                question={
                "What is the machine used for?"
                }
            />
            <YesNoQuestion
                id={"osteopathy.laserMachineUsedBy"}
                path={"osteopathy.laserMachineUsedBy"}
                question={
                "Will there be other practitioners operating the equipment?"
                }
            />
            <YesNoQuestion
                id={"osteopathy.laserMachineTraining"}
                path={"osteopathy.laserMachineTraining"}
                question={
                "Are you properly trained for operations of this laser machine?"
                }
            />
            
            </>)
        : null}
        {values.osteopathy.coldOrLowLevelLasers === "yes"
        ?  (<UploadQuestion
            question={"Please upload training certificate for the use of the machine"}
            fileCollectionName={"osteopathyLaserMachineFiles"}
           />)
        :null}

        <br />
      </Card>
    );
  }
}
