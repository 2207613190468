import React from "react";
import Form from "./ReflexologyForm";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;

  return {
    visible:
      formReducer.values.applicantInformation.modalities.indexOf(
        "Reflexology"
      ) >= 0,
    values: formReducer.values
  };
};

const mapDispatchToProps = dispatch => {
  return {
    displayGenericModal: message => {
      dispatch({ type: "GENERIC_MODAL", message });
    },
    clearForm: message => {
      dispatch({ type: "HANDLE_REFLEXOLOGY", message });
    }
  };
};

const ConnectedForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);

export default ConnectedForm;
