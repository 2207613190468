function setValue(object, newValue, path) {
  var original = "a " + path;

  try {
    var stack = path.split(".");

    while (stack.length > 1) {
      object = object[stack.shift()];
    }

    object[stack.shift()] = newValue;
  } catch (err) {
    console.log("object does not have value in given path", object, original);
    console.error("object does not have value in given path", object, original);
    throw err;
  }
}

export default setValue;
