import values from "./values";
import validation from "./validation";

const paymentOption = {
  selection: {
    defaultvalue: "Broker",
    validation: (value) => value !== "Broker",
    question: "Payment Option"
  },
  total: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Total"
  }
};
export default paymentOption;
