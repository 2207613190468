import values from "./values";
import validation from "./validation";

const clientInformation = {
  businessName: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Business Name",
    info: ""
  },
  firstName: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "First Name",
    info: ""
  },
  lastName: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Last Name",
    info: ""
  },
  phoneNumber: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Phone Number",
    info: ""
  },
  address: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Address",
    info: ""
  },
  city: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "City",
    info: ""
  },
  province: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Province",
    info: ""
  },
  email: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Email",
    info: ""
  },
  fax: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.OPTIONAL,
    question: "Fax",
    info: ""
  },
  businessWebsite: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.OPTIONAL,
    question: "Business website",
    info: ""
  },

  newPolicyyes_no: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "I am applying for a new policy.",
    info: ""
  },
  exisitngPolicyyes_no: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "I am renewing an existing policy.",
    info: ""
  },
  expiring_policyyes_no: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you currently have an active liability insurance policy with another insurance provider?",
    info: "(Please note if you are renewing your current policy just click No)"
  },
  expiring_policydate: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Expiry Date",
    info: ""
  },
  expiring_policyLMI: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Is this policy provided by BrokerLink?",
    info: ""
  },
  expiring_policypolicyNumber: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Policy Number",
    info: ""
  },
  expiring_policydifferentDate: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "If you wish a different start date indicate here. If not, your policy will start the next day at 12:01 AM",
    info:
      "(Please note this is only applies if you are Applying for a new policy)"
  },
  trainingCertificate: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you have a training certificate for all the modalities you are practicing?",
    info:
      "Please note this is only required if you are adding a new modality or are a new client. This is not required if you are renewing an existing policy."
  },
  postalcode: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Postal Code",
    info: ""
  },
  modalities: {
    defaultvalue: values.EMPTY_ARRAY,
    validation: validation.NOT_EMPTY_ARRAY,
    question: "Modalities",
    info: ""
  },
  otherModalities: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Other Modalities",
    info: ""
  },
  sellProductsyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you sell any products?",
    info: ""
  },
  sellProductsdescription: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please list the products you sell.",
    info: ""
  },
  manufactureProductsyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you manufacture any products?",
    info: ""
  },
  manufactureProductsdescription: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please list the products you manufacture.",
    info: ""
  },
  manufactureProductsToBeSold:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you sell these products either online or in a store?",
    info: ""
  },
  grossSales: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Please indicate your estimated gross annual income for the next 12 months:",
    info: ""
  },
  equipmentCost:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "What is the estimated value of the equipment used for your business?",
    info: "Generally refers to all contents usual to your business, including furniture, machinery, tools, appliances. For example: computers, massage tables, laser machine, etc."
  },
  stockCost:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.VALID_CURRENCY,
    question:
      "What is the estimated value of the stock used for your business?",
    info: "Generally refers to all merchandise usual to your business. For example, massage oils, printing papers, advertising materials, etc"
  },
  stockContentsDescription:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Provide a brief description of contents",
    info: ""
  },
  independentyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are you an employee / an independent contractor / or a sub-contractor for a clinic, spa or studio?",
    info: ""
  },
  independentwfh: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you work from your home?",
    info: ""
  },
  independentsubc: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Please advise how many subcontractors/employees are currently working with you",
    info: ""
  },
  numbersubc: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please explain your situation:",
    info: ""
  },
  independentspaClinicStudioOwner: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Are you a Commercial Clinic, Spa or studio owner?",
    info: ""
  },
  independentspacSub: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you have any sub-contractors or employees working at your clinic?",
    info: ""
  },
  independentnumberOfEmployees: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "How many sub contractors and how many Employees do you have working at your clinic?",
    info: ""
  },
  independentemployeeModalities: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please list the modalities they practice",
    info: ""
  },
  independentrent: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you rent or own the space you run your business out of?",
    info: ""
  },
  independentclinicLocation: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please provide the address of your business location",
    info: ""
    // info:
    //   "(This to verify  the  coverages under this policy  is sufficient enough for your business)"
  },
  independentclinicSize: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please indicate the size of your clinic is SQFT.",
    info: ""
    // info:
    //   "(This to verify  the  coverages under this policy  is sufficient enough for your business)"
  },
  independentSublet:{
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"Do you sub-let, sub-lease or rent out any portion of your space to others?",
    info:""
  },
  independentseparatePolicy: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:"Do you have a separate policy for your business space?",
    info: ""
  },
  independentcommercialPolicy: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you have a commercial policy which includes Contents coverage, Commercial General Liability &  Professional Liability (E&O) for your business Entity?",
    info: ""
  },
  independentpartner: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you have a business partner?",
    info: ""
  },
  independentsituation: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please explain your situation: ",
    info: ""
  },
  independentbusinessStructure: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Other- please explain your business structure",
    info: ""
  }
};

export default clientInformation;
