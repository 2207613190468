import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import LacknerMcLennan from "./reducers";

let devtools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : null;
let store;
if (devtools) {
  store = createStore(
    LacknerMcLennan,
    compose(
      applyMiddleware(...[thunk]),
      devtools
    )
  );
} else {
  store = createStore(LacknerMcLennan, compose(applyMiddleware(...[thunk])));
}
export default store;
