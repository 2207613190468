import { connect } from "react-redux";
import Home from "./Home";
const mapStateToProps = state => {
  const { formReducer } = state;

  return {
    successFullSubmission: formReducer.successFullSubmission
  };
};

export default connect(
  mapStateToProps,
  null
)(Home);
