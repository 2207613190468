import values from "./values";
import validation from "./validation";

const nc = {
  CANNP: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are you a member of the Canadian Association Natural Nutritional Practitioners (CANNP)"
  },
  CSNNAA: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are you a member of the Canadian School of Natural Nutrition Alumni Association (CSNNAA)?"
  },
  consultation: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "One-on-One Consultation:"
  },
  supplements: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Supplements and product resales: (Items you buy at wholesale without tampering or relabeling and resell to your clients)"
  },
  detox: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Meal plans/detoxification plans/Personalized Wellness plans:"
  },
  speech: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Group seminars/public speaking:"
  },
  onlinecouse: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Online courses (Detox courses, cooking, etc.)"
  },
  teaching: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question:
      "Teaching (are you certifying others to be a nutritional consultant)"
  },
  resale: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Book Resales (books you have not written):"
  },
  web: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "How much of your business is web-based?"
  },
  supplementsResale: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you purchase Supplements and/or other products in bulk and repackage them to sell to your clients?*"
  },
  canceryesNo: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Are you a Certified Cancer Coach?"
  },
  canceraddCoverage: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    info:
      "Please Note: You must include a copy of your certificate of training from the Canadian Cancer Association, if you have not already done so.",
    question:
      "Do you wish to add Certified Cancer Coach coverage to your policy?"
  },
  bioenergeticsyesNo: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Do you Offer Bioenergetics Intolerance Elimination?"
  },
  bioenergeticsaddCoverage: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    info:
      "Please note your practice must include client signed waivers, health declaration forms if using BIE machine, maintenance must be maintained as per manufactures guidelines.",
    question:
      "Do you wish to add Bioenergetics Intolerance Elimination coverage to your policy?"
  },
  cookingyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you offer Classes where the participants are preparing and cooking, or do you provide catering services? "
  },
  cookingaddCoverage: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Do you wish to add Culinary Extension coverage to your policy?",
    info:
      " This coverage enhancement includes the following: Up to $10,000 in annual receipts for catering and / or food sales, Cooking classes included where participants are preparing the meals, Cooking demonstrations / catering for small groups or private parties"
  },
  consultingyesNo: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Do you consult or offer programs with Clients via the internet?",
    info:
      "If you consult with clients via the internet (website, social media), international Telephone call, Skype, or Face Time you may purchase coverage for International Web Based consulting"
  },
  consultingaddCoverage: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    info:
    "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
    advise, or information, where you cannot control whether it’s a client within Canada or outside of\
    Canada, viewing your advice or content.\
    If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
    Please consider adding the International Web Based Extension to your policy.\
    Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
    must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
    client prior to a session.",
    question:
      "Do you wish to add International Web Based Consulting to your policy?"
  },
  microscopyyesNo: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Do you offer Live Blood Cell Microscopy?"
  },
  microscopyaddCoverage: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question: "Do you wish to add Live Blood Cell Microscopy to your policy?",
    info:
      "Please Note: You must include a copy of your certificate of training, if you have not already done so."
  },
  ebookyesNo: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    question:
      "Have you written any books or e-books that were either published or self published?"
  },
  ebookaddCoverage: {
    defaultvalue: values.EMPTY_STRING,

    validation: validation.NOT_EMPTY_STRING,
    info:
      "Publisher’s Liability coverage provides the following for an additional $75.00 annually If you have an incidental publishing, such as writing articles in published magazines or online, written a book that has been either published in paper or as an E-book, you should consider Publisher’s liability extension. Whether your e-book is free on your website or you sell them, your exposure is much higher and the individual policy does not cover published writings. Publisher’s liability gives you $25,000 in coverage should someone decide to sue you based on something you published. Please select to add to your policy.",
    question: "Do you wish to add Publisher’s Liability to your policy?"
  },
  consultingClientele:{
    defaultValue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    info: "",
    question: "Is your clientele more than 50% outside of Canada"
    },
  consultingCharge:{
        defaultValue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        info: "",
        question: "Add International coverage to your policy, for an additional $"
    },
    personalTrainerAddCoverage:{
        defaultValue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        info: "Please Note: A Personal Trainer under this policy cannot have more than 2 clients at a time and weights cannot exceed 80lb.",
        question: "Would you like to add Personal Training coverage?"
    },
    personalTrainerAddCoverageOption:{
        defaultValue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        info: "Please Note: A Personal Trainer under this policy cannot have more than 2 clients at a time and weights cannot exceed 80lb.",
        question: ""
    },
    fitnessInstructorAddCoverage:{
        defaultValue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question: "Would you like to add Fitness Training coverage?",
        info: "Please Note: A Fitness Instructor under this policy cannot use weights that exceed 20lbs."
    }
};

export default nc;
