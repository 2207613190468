import Agreement from "./Agreement";
import { getValue } from "../../utils";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;
  if (!ownProps.path) {
    throw new Error("no path " + ownProps.id, ownProps);
  }

  const formState = getValue(formReducer.values, ownProps.path);
  const valid = getValue(formReducer.valid, ownProps.path);

  return {
    error: {
      value: formReducer.submissionAttempted && !valid,
      message: "* Indicate your agreement"
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (path, value) =>
      dispatch({ type: "UPDATE_FORM", path, value }),
    handleMount: path => dispatch({ type: "MOUNTED", path: ownProps.path }),
    handleUnmount: path => dispatch({ type: "UNMOUNTED", path: ownProps.path })
  };
};

const ConnectedYesNoInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(Agreement);

export default ConnectedYesNoInput;
