import React from "react";
import FileUploadInput from "./FileUploadInput";
import { connect } from "react-redux";
import { setFiles } from "../../store/actions";

const mapDispatchToProps = dispatch => {
  return {
    handleFiles: (files, type) => {
      return dispatch(setFiles(files, type));
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  const { fileReducer } = state;
  return { fileNames: fileReducer[ownProps.fileCollectionName].names };
};

const ConnectedFileUploadInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadInput);

export default ConnectedFileUploadInput;
