import getValue from "./getValue";
import handlePricing from "./handlePricing";



const createPath = (obj, path, value = null) => {
    path = typeof path === "string" ? path.split(".") : path;
    let current = obj;
    while (path.length > 1) {
        const [head, ...tail] = path;
        path = tail;
        if (current[head] === undefined) {
            current[head] = {};
        }
        current = current[head];
    }
    current[path[0]] = value;
    return obj;
};

function getInitialState(def) {
    var arr = [];
    Object.keys(def).forEach(function (item) {
        Object.keys(def[item]).forEach(function (item1) {
            arr.push(item + "." + item1);
        })
    })

    var temp1 = {};
    var temp2 = {};
    var temp3 = {};
    // console.log(arr)

    arr.forEach(item => {
        if (item.length) {
            temp1 = createPath(temp1, item, getValue(def, item + ".defaultvalue"));
            temp2 = createPath(temp2, item, true);
            temp3 = createPath(temp3, item, false);
        }
    });

    temp1["paymentOption"]["total"] = 0;

    return {
        values: temp1,
        valid: temp2,
        visible: temp3,
        pricing: handlePricing(temp1),
        submissionAttempted: false,
        validForm: false,
        successFullSubmission: false
    };
}

export default getInitialState;
