import values from "./values";
import validation from "./validation";

const fitnessInstructor = {
  selectedOption: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Choose a Fitness Instructor policy:"
  },
  bootcampyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you offer bootcamps?"
  },
  bootcampdescription: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please describe the bootcamp briefly"
  },
  paddleboardyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Does your instruction include Stand Up Paddleboard Yoga/fitness?"
  },
  paddleboardq1: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,

    question: "Are you currently certified in CPR? "
  },
  paddleboardq2: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you have a water safety or life saving society certificate?"
  },
  paddleboardq3: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "What is the maximum number of students allowed in one class?"
  },
  paddleboardq4: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Are introductory or first time lessons on shore?"
  },
  paddleboardq5: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Are life jackets worn by every student, including you?"
  },
  paddleboardq6: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do first time users where life jackets?"
  },
  paddleboardq7: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "If some students are not wearing a life jacket are the life jackets on the board or close by?"
  },
  paddleboardq8: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Are leashes used while students are on the boards?"
  },
  paddleboardq9: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Is the maximum depth of the water where lessons are held more than 10 feet deep?"
  },
  paddleboardq10: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "How far off shore do you practice?"
  },
  paddleboardq11: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you use board anchors?"
  },
  paddleboardq12: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Other details regarding Stand Up Paddleboard Yoga you would like to share:"
  },
  paddleboardaddCoverage: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you wish to add Stand Up Paddleboard coverage to your policy?",
    info:
      "To add coverage for Stand Up Paddleboard  to your policy, the additional premium will be $100.00 annually"
  },
  consultingyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you consult or offer programs with Clients via the internet?"
  },
  consultingaddCoverage: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Do you wish to add International Web Based Consulting to your policy?",
    info:
    "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
    advise, or information, where you cannot control whether it’s a client within Canada or outside of\
    Canada, viewing your advice or content.\
    If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
    Please consider adding the International Web Based Extension to your policy.\
    Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
    must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
    client prior to a session."
  },
  consultingClientele:{
    defaultValue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    info: "",
    question: "Is your clientele more than 50% outside of Canada"
    },
  consultingCharge:{
        defaultValue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        info: "",
        question: "Add International coverage to your policy, for an additional $"
    }
};

export default fitnessInstructor;
