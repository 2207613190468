import values from "./values";
import validation from "./validation";
const psychologist = {
  selectedOption: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please choose from one of the following options."
  },
  gcl: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you wish to add Commercial General Liability coverage to your policy?",
    info: "$5,000,000 CGL – cost is an additional $270.00"
  }
};

export default psychologist;
