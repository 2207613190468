import form from "constants/form";
import {
  setValue,
  getValue,
  handlePricing,
  flattenObject,
  getInitialState,
} from "utils";
import produce from "immer";

const initialState = getInitialState(form);

const formReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case "UPDATE_FORM":
        const temp = produce(state, function (draftState) {
          var vals = getValue(form, action.path);

          setValue(draftState.values, action.value, action.path);
          setValue(
            draftState.valid,
            vals.validation(action.value),
            action.path
          );

          var pricing = handlePricing(draftState.values);

          let finalPremium = Object.values(pricing).reduce(
            (acc, curr) => Math.max(acc, curr.premium || 0),
            0
          );
          let finalOptions = Object.values(pricing).reduce(
            (acc, curr) => acc + curr.optional || acc,
            0
          );

          let totalstripe = (finalPremium + finalOptions) * pricing.tax;
          draftState.values["paymentOption"]["total"] = totalstripe;
          draftState.validForm = false;

          draftState.pricing = handlePricing(draftState.values);
        });

        return temp;

        break;
      case "MOUNTED":
        if (state.successFullSubmission) {
          return state;
        }

        return produce(state, function (draftState) {
          var vals = getValue(form, action.path);
          setValue(draftState.visible, true, action.path);
          setValue(
            draftState.valid,
            // This piece of code will add default validation as function as previous value in redux state
            vals.validation(getValue(draftState.values, action.path)),
            action.path
          );
          draftState.validForm = false;
        });

        break;
      case "UNMOUNTED":
        if (state.successFullSubmission) {
          return state;
        }

        return produce(state, function (draftState) {
          var vals = getValue(form, action.path);
          setValue(draftState.visible, false, action.path);
          setValue(draftState.valid, true, action.path);
          setValue(draftState.values, vals.defaultvalue, action.path);
          draftState.validForm = false;
          draftState.pricing = handlePricing(draftState.values);
        });

        break;
      case "SUBMISSION":
        return produce(state, function (draftState) {
          draftState.submissionAttempted =
            draftState.submissionAttempted || true;
          var allValues = flattenObject(draftState.valid);
          var allVisible = flattenObject(draftState.visible);
          draftState.validForm = Object.keys(allValues).every(
            (item) => allValues[item] || !allVisible[item]
          );
        });

      case "FORM_SUBMISSION_SUCCESS":
        return produce(state, function (draftState) {
          draftState.successFullSubmission = true;
        });
      case "FORM_SUBMISSION_ERROR":
        return produce(state, function (draftState) {
          draftState.successFullSubmission = false;
        });

      case "HANDLE_RMT_BC_ON":
        return produce(state, function (draftState) {
          if (
            ["Ontario", "British Columbia"].indexOf(
              draftState.values.applicantInformation.province
            ) < 0
          ) {
            draftState.values.applicantInformation.modalities = draftState.values.applicantInformation.modalities.filter(
              (item) => {
                return item !== "RMT";
              }
            );

            var vals = getValue(form, "applicantInformation.modalities");

            setValue(
              draftState.valid,
              vals.validation(
                getValue(draftState.values, "applicantInformation.modalities")
              ),
              "applicantInformation.modalities"
            );
            draftState.pricing = handlePricing(draftState.values);
            draftState.validForm = false;
          }
        });

        //Observe this and remove June 2023
      case "HANDLE_NMOS":
        return produce(state, function (draftState) {
          draftState.values.applicantInformation.modalities = draftState.values.applicantInformation.modalities.filter(
            (item) => {
              return item !== "Osteopathy";
            }
          );
          var vals = getValue(form, "applicantInformation.modalities");

          setValue(
            draftState.valid,
            vals.validation(
              getValue(draftState.values, "applicantInformation.modalities")
            ),
            "applicantInformation.modalities"
          );
          draftState.pricing = handlePricing(draftState.values);
          draftState.validForm = false;
        });

      case "HANDLE_YOGA":
        return produce(state, function (draftState) {
          draftState.values.applicantInformation.modalities = draftState.values.applicantInformation.modalities.filter(
            (item) => {
              return item !== "Yoga";
            }
          );
          var vals = getValue(form, "applicantInformation.modalities");

          setValue(
            draftState.valid,
            vals.validation(
              getValue(draftState.values, "applicantInformation.modalities")
            ),
            "applicantInformation.modalities"
          );
          draftState.pricing = handlePricing(draftState.values);
          draftState.validForm = false;
        });

      case "HANDLE_NC":
        return produce(state, function (draftState) {
          draftState.values.applicantInformation.modalities = draftState.values.applicantInformation.modalities.filter(
            (item) => {
              return item !== "NC";
            }
          );
          var vals = getValue(form, "applicantInformation.modalities");

          setValue(
            draftState.valid,
            vals.validation(
              getValue(draftState.values, "applicantInformation.modalities")
            ),
            "applicantInformation.modalities"
          );
          draftState.pricing = handlePricing(draftState.values);
          draftState.validForm = false;
        });

      case "HANDLE_REFLEXOLOGY":
        return produce(state, function (draftState) {
          draftState.values.applicantInformation.modalities = draftState.values.applicantInformation.modalities.filter(
            (item) => {
              return item !== "Reflexology";
            }
          );
          var vals = getValue(form, "applicantInformation.modalities");

          setValue(
            draftState.valid,
            vals.validation(
              getValue(draftState.values, "applicantInformation.modalities")
            ),
            "applicantInformation.modalities"
          );
          draftState.pricing = handlePricing(draftState.values);
          draftState.validForm = false;
        });

      case "HANDLE_ACC":
        return produce(state, function (draftState) {
          draftState.values.applicantInformation.modalities = draftState.values.applicantInformation.modalities.filter(
            (item) => {
              return item !== "Acupuncture/TCM";
            }
          );
          var vals = getValue(form, "applicantInformation.modalities");

          setValue(
            draftState.valid,
            vals.validation(
              getValue(draftState.values, "applicantInformation.modalities")
            ),
            "applicantInformation.modalities"
          );
          draftState.pricing = handlePricing(draftState.values);
          draftState.validForm = false;
        });

      /** Addition: Nov 1, 2020 (Butch)
       *  New physio plans have different pricing than renewals
       */
      case "HANDLE_PHYSIO_NEW":
        return produce(state, function (draftState) {
          var vals = getValue(form, "applicantInformation.modalities");
          setValue(
            draftState.valid,
            vals.validation(
              getValue(draftState.values, "applicantInformation.modalities")
            ),
            "applicantInformation.modalities"
          );

          if (
            draftState.values.applicantInformation.newPolicyyes_no !== "yes"
          ) {
            draftState.pricing = handlePricing(draftState.values);
          } else {
            draftState.pricing = handlePricing(draftState.values);
          }

          draftState.validForm = false;
        });

      default:
        return state;

        break;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }

  return state;
};

export default formReducer;
