import React, { useState } from 'react';
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
    return {
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`
    };
}
const styles = theme => ({
    paper: {
        position: "absolute",
        width: "500px",
        maxWidth: "80%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: "none"
    }
});

const ModalOneTime = props => {
    const [open, setOpen] = useState(props.open); //SET THE LOCAL STATE FROM THE PROPS PASSED FROM PARENT
    const [message, setMessage] = useState(props.message);
    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={() => { setOpen(false) }} //TOGGLE ON CLOSE
            >
                <div style={getModalStyle()} className={props.classes.paper}>
                    <Typography variant="h6" id="modal-title">
                        Thank you
                    </Typography>
                    <Typography variant="subtitle1" id="simple-modal-description">
                        {message}
                    </Typography>
                    <br />
                    <br />

                    <Button onClick={() => { setOpen(false); window.location.href = "https://ineedapolicy.com"; }}>Close</Button>
                </div>
            </Modal>
        </div >
    );
};

const ModalOneTimeStyled = withStyles(styles)(ModalOneTime);

export default ModalOneTimeStyled;