import { getValue } from "../../utils";
import Autocomplete from "./Autocomplete";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;

  const { submissionAttempted } = formReducer;
  const formState = getValue(formReducer.values, ownProps.path);
  const valid = getValue(formReducer.valid, ownProps.path);

  return {
    error: {
      value: formReducer.submissionAttempted && !valid,
      message: "* This field is required"
    },
    value: formState
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (path, value) => {
      dispatch({ type: "UPDATE_FORM", path: ownProps.path, value });
    },
    handleMount: () => {
      dispatch({ type: "MOUNTED", path: ownProps.path });
    },
    handleUnmount: () => {
      dispatch({ type: "UNMOUNTED", path: ownProps.path });
    }
  };
};

const ConnectedAutocomplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(Autocomplete);

export default ConnectedAutocomplete;
