import React, { useState, useRef } from "react";
import shortid from "shortid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Folder from "@material-ui/icons/Folder";

import styles from "./FileUploadInput.module.css";

const MaterialFileUpload = props => {
  const id = shortid.generate();

  const inputEl = useRef(null);
  const { error, fileCollectionName, fileNames, handleFiles } = props;
  const isError = error && error.value === true;

  return (
    <>
      <input
        accept="image/*,application/pdf"
        // className={classes.input}
        style={{ display: "none" }}
        onChange={e => {
          handleFiles(e.target.files, fileCollectionName);
        }}
        id={id}
        multiple
        type="file"
        ref={inputEl}
      />
      <OutlinedInput
        onBlur={props.onBlur}
        onClick={() => {
          inputEl.current.click();
        }}
        value={
          fileNames && fileNames.length
            ? fileNames
              .toString()
              .split(",")
              .join(", ")
            : " "
        }
        readOnly
        margin="none"
        startAdornment={
          <Folder
            style={{
              color: isError ? "rgb(244, 67, 54)" : "rgba(0, 0, 0, 0.23)",
              marginRight: "10px"
            }}
          />
        }
        placeholder={" Click to upload "}
        margin="none"
        variant="outlined"
        style={{ width: "100%", marginTop: 15 }}
        error={error && error.value === true}
      />
      {isError === true ? (
        <span className={styles.helpText}>{props.error.message}</span>
      ) : (
          ""
        )}
    </>
  );
};
export default MaterialFileUpload;
