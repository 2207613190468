import { getValue } from "../../utils";
import SelectInput from "./SelectInput";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { formReducer } = state;
  const formState = getValue(formReducer.values, ownProps.path);
  const valid = getValue(formReducer.valid, ownProps.path);

  return {
    error: {
      value: formReducer.submissionAttempted && !valid,
      message: "* This field is required"
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (path, value) =>
      dispatch({ type: "UPDATE_FORM", path, value }),
    handleMount: () => dispatch({ type: "MOUNTED", path: ownProps.path }),
    handleUnmount: () => dispatch({ type: "UNMOUNTED", path: ownProps.path })
  };
};

const ConnectedSelectInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectInput);

export default ConnectedSelectInput;
