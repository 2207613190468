import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import styles from "./CurrencyInput.module.css";

const CurrencyInput = props => {
  const { id, handleChange, error, handleMount, handleUnmount } = props;
  const [value, setValue] = useState(props.value);
  const [errorValue, setErrorValue] = useState(error.value);
  
  useEffect(() => {
    handleMount();
    return () => {
      handleUnmount();
    };
  }, []);

  return (
    <>
      <TextField
        autoComplete="off"
        variant="outlined"
        type="text"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{ id: id, startAdornment:"$ " }}
        id={id}
        name={id}
        onBlur={e => {
            //Allow commas for currency.
            if(new RegExp("^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$","gm").test(e.target.value)||e.target.value===""){
                const cleanedCurrency = e.target.value.replace(",","");
                handleChange(id, cleanedCurrency);
                setErrorValue(false);
            }else{
                setErrorValue(true);
            }
        }}
        onChange={e => {
          setValue(e.target.value);
        }}
        value={value}
        margin="normal"
        color="primary"
        fullWidth
        error={(error && error.value === true)||(errorValue === true)}
      />

      {error && error.value === true ? (
        <span className={styles.helpText}>{props.error.message}</span>
      ) : (
        ""
      )}
     {errorValue && errorValue === true ? (
        <span className={styles.helpText}>
            {"Incorrect format. Numbers need to be either whole or with comma (eg: $1000 or $1,000)."}
        </span>
      ) : (
        ""
      )}
    </>
  );
};

export default CurrencyInput;
