import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { Select } from "@material-ui/core";
import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion
} from "../helpers";

export default class Psychologist extends React.Component {
  render() {
    const { values, visible } = this.props;

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Psychologist
        </Typography>
        <hr />

        <MultiSelectQuestion
          question={"Please choose from one of the following options."}
          id={"psychologist.selectedOption"}
          path={"psychologist.selectedOption"}
          options={[
            {
              option: "Option 1 - Premium 370$",
              value: "option1",
              info: (
                <>
                  {" "}<br />
                  $1,000,000 Professional Liability <br /> $25,000 Legal Expense<br /> $10,000 Criminal Expense<br />{" "}
                </>
              )
            },
            {
              option: "Option 2 - Premium 520$",
              value: "option2",
              info: (
                <>
                  {" "}<br />
                  $5,000,000 Professional Liability <br /> $25,000 Legal Expense<br /> $10,000 Criminal Expense<br />{" "}
                </>
              )
            },
          ]}
        />

        <br />
        <br />
        <Typography variant={"h6"}> Optional coverage</Typography>
        <br />


        <AdditionalCoverageQuestion
          coverageName={"Commercial General Liability"}
          info={"$5,000,000 CGL – cost is an additional $270.00"}
          id={"psychologist.gcl"}
          price={270}
        />
      </Card>
    );
  }
}
