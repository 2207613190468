import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { Select } from "@material-ui/core";
import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion,
  InternationalClienteleQuestion,
  InternationalConsultingChargeQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class FiForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].fitnessInstructorPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].fitnessInstructorPrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Fitness Instructor
        </Typography>
        <hr />

        <MultiSelectQuestion
          question={"Choose a Fitness Instructor policy:"}
          id={"fitnessInstructor.selectedOption"}
          path={"fitnessInstructor.selectedOption"}
          options={[
            {
              option:
                values.yoga.modo === "yes" ||
                  values.yoga.yyoga === "yes" ||
                  values.yoga.power === "yes" ? "Standard Policy -  weights don't exceed 20lbs" : "Standard Policy $" + thisPricing.premium.toFixed(2) + " premium / weights don't exceed 20lbs",
              value: "standard"
            }
          ]}
        />

        <YesNoQuestion
          id={"fitnessInstructor.bootcampyesNo"}
          path={"fitnessInstructor.bootcampyesNo"}
          question={"Do you offer bootcamps?"}
        />

        {values.fitnessInstructor.bootcampyesNo === "yes" ? (
          <TextQuestion
            id={"fitnessInstructor.bootcampdescription"}
            path={"fitnessInstructor.bootcampdescription"}
            question={"Please describe the bootcamp briefly"}
          />
        ) : (
            ""
          )}

        <YesNoQuestion
          id={"fitnessInstructor.paddleboardyesNo"}
          path={"fitnessInstructor.paddleboardyesNo"}
          question={
            "Does your instruction include Stand Up Paddleboard Yoga/fitness?"
          }
        />

        {values.fitnessInstructor.paddleboardyesNo === "yes" ? (
          <>
            <Col xs={12} sm={12}>
              <Typography variant={"h6"}> Paddleboard Coverage </Typography>
            </Col>
            {/* Paddleboard q1 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong> Are you currently certified in CPR? </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput
                  id="fitnessInstructor.paddleboardq1"
                  path="fitnessInstructor.paddleboardq1"
                  path="fitnessInstructor.paddleboardq1"
                />
              </Col>
            </Row>

            {/* Paddleboard q2 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Do you have a water safety or life saving society
                      certificate?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput
                  id="fitnessInstructor.paddleboardq2"
                  path="fitnessInstructor.paddleboardq2"
                />
              </Col>
            </Row>

            {/* Paddleboard q3 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      What is the maximum number of students allowed in one
                      class?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <TextInput
                  id="fitnessInstructor.paddleboardq3"
                  path="fitnessInstructor.paddleboardq3"
                />
              </Col>
            </Row>

            {/* Paddleboard q4 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Are introductory or first time lessons on shore?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput
                  id="fitnessInstructor.paddleboardq4"
                  path="fitnessInstructor.paddleboardq4"
                />
              </Col>
            </Row>

            {/* Paddleboard q5 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Are life jackets worn by every student, including you?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput
                  id="fitnessInstructor.paddleboardq5"
                  path="fitnessInstructor.paddleboardq5"
                />
              </Col>
            </Row>

            {/* Paddleboard q6 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong> Do first time users where life jackets? </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput
                  id="fitnessInstructor.paddleboardq6"
                  path="fitnessInstructor.paddleboardq6"
                />
              </Col>
            </Row>

            {/* Paddleboard q7 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      If some students are not wearing a life jacket are the
                      life jackets on the board or close by?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput
                  id="fitnessInstructor.paddleboardq7"
                  path="fitnessInstructor.paddleboardq7"
                />
              </Col>
            </Row>

            {/* Paddleboard q8 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Are leashes used while students are on the boards?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput
                  id="fitnessInstructor.paddleboardq8"
                  path="fitnessInstructor.paddleboardq8"
                />
              </Col>
            </Row>

            {/* Paddleboard q9 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Is the maximum depth of the water where lessons are held
                      more than 10 feet deep?
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput
                  id="fitnessInstructor.paddleboardq9"
                  path="fitnessInstructor.paddleboardq9"
                />
              </Col>
            </Row>

            {/* Paddleboard q10 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong> How far off shore do you practice? </strong>
                  </Typography>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <TextInput
                  id="fitnessInstructor.paddleboardq10"
                  path="fitnessInstructor.paddleboardq10"
                />
              </Col>
            </Row>

            {/* Paddleboard q11 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong> Do you use board anchors?</strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput
                  id="fitnessInstructor.paddleboardq11"
                  path="fitnessInstructor.paddleboardq11"
                />
              </Col>
            </Row>

            {/* Paddleboard q12 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Other details regarding Stand Up Paddleboard Yoga you
                      would like to share:{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <TextInput
                  id="fitnessInstructor.paddleboardq12"
                  path="fitnessInstructor.paddleboardq12"
                />
              </Col>
            </Row>
          </>
        ) : (
            ""
          )}

        {values.fitnessInstructor.paddleboardyesNo === "yes" ? (
          <AdditionalCoverageQuestion
            coverageName=" Stand Up Paddleboard Yoga"
            id="fitnessInstructor.paddleboardaddCoverage"
            path="fitnessInstructor.paddleboardaddCoverage"
            info={
              "To add coverage for Stand Up Paddleboard  to your policy, the additional premium will be $" + thisPricing.paddleboard.toFixed(2) + " annually"
            }
            price={thisPricing.paddleboard}
          />
        ) : (
            ""
          )}

        <YesNoQuestion
          id={"fitnessInstructor.consultingyesNo"}
          path={"fitnessInstructor.consultingyesNo"}
          question={
            "Do you consult or offer programs with Clients via the internet?"
          }
        />

        {values["fitnessInstructor"]["consultingyesNo"] === "yes" ? (
            <>
          <AdditionalCoverageQuestion
            coverageName="International Web based extension"
            id="fitnessInstructor.consultingaddCoverage"
            path="fitnessInstructor.consultingaddCoverage"
            type="international"
            info={
                "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
                advise, or information, where you cannot control whether it’s a client within Canada or outside of\
                Canada, viewing your advice or content.\
                If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
                Please consider adding the International Web Based Extension to your policy.\
                Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
                must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
                client prior to a session."
            }
            price={thisPricing.webconsulting}
          />
  
        <InternationalClienteleQuestion
            id="fitnessInstructor.consultingClientele"
            type="international"
        />
        { values.fitnessInstructor.consultingClientele === "yes" ?
                <strong>Please complete the form and one of our Complementary Health Brokers will contact you.</strong>
                :  <InternationalConsultingChargeQuestion
                        id="fitnessInstructor.consultingCharge"
                    />
        }
          </>
        ) : (
            ""
          )}
      </Card>
    );
  }
}
