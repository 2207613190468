import values from "./values";
import validation from "./validation";

const osteopathy = {
  becomingMember: {
    defaultvalue: values.EMPTY_STRING,
    visible: false,
    valid: true,
    validation: validation.NOT_EMPTY_STRING,
    value: values.EMPTY_STRING,
    question: "Are you a member of an association?"
    },
    associationPicker: {
        defaultvalue: values.EMPTY_STRING,
        visible: false,
        valid: true,
        validation: validation.NOT_EMPTY_STRING,
        value: values.EMPTY_STRING,
        question: "Please choose one of the Osteopathy Association, you are a current member of"
      },
      otherAssociation: {
        defaultvalue: values.EMPTY_STRING,
        visible: false,
        valid: true,
        validation: validation.NOT_EMPTY_STRING,
        value: values.EMPTY_STRING,
        question: "Name of other association?"
  },
  student: {
    defaultvalue: values.EMPTY_STRING,
    visible: false,
    valid: true,
    validation: validation.NOT_EMPTY_STRING,
    value: values.EMPTY_STRING,
    question: "Are you an Osteopathy student?"
  },
  completedStudentyesNo: {
    defaultvalue: values.EMPTY_STRING,
    visible: false,
    valid: true,
    validation: validation.NOT_EMPTY_STRING,
    value: values.EMPTY_STRING,
    question: "Have you completed all the courses in your program, and are going onto your thesis/practium?",
      info: "If you've completed your in class program or going into your last year, you are no longer eligible for the Osteopathy Student rate, you will be charged the full $405.00 premium."
  },
  laser: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you offer any type of Laser therapy (Use a Laser machine)?"
  },
  laserDescribe: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please describe"
  },
  coldOrLowLevelLasers:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Is this a Cold lasers or low-level lasers machine?"
  },
  ownLaserMachine:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Do you own the Laser machine?"
  },
  laserMachineDetails:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Please provide us with the Type of laser machine, make, model, S/N"
  },
  laserMachineAge:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Please provide us with the age of machine, where was it manufactured & value"
  },
  laserMachineMobile:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Please advise if this laser equipment will be used in the clinic only or in different locations / mobile"
  },
  laserMachineWaivers:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Are waivers being signed by each participant?"
  },
  laserMachineMaintenance:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"What is the maintenance for this equipment?"
  },
  laserMachineLeasedOwned:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Is the equipment leased or owned by you?"
  },
  laserMachineUse:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"What is the machine used for?"
  },
  laserMachineUsedBy:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Will there be other practitioners operating the equipment?"
  },
  laserMachineTraining:{
      defaultvalue: values.EMPTY_STRING,
      validation: validation.NOT_EMPTY_STRING,
      question:"Are you properly trained for operations of this laser machine?"
  }
};

export default osteopathy;