import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./SelectInput.module.css";

const MaterialSingleSelect = props => {
  const [value, setValue] = useState(props.value || " ");
  const { handleChange, id, error, handleMount, handleUnmount } = props;
  const isError = error && error.value === true;

  useEffect(() => {
    handleMount();
    return () => {
      handleUnmount();
    };
  }, []);
  return (
    <>
      <Select
        input={
          <OutlinedInput
            name="applicantInformation.province"
            id="applicantInformation.province"
            onChange={e => {
              setValue(e.target.value);
              handleChange(id, e.target.value);
            }}
            // onBlur={props.onBlur}
            margin="none"
            error={error && error.value === true}
          />
        }
        style={{ width: "100%", marginTop: 15, marginBottom: 15, ...props.styles }}
        name={"props.label"}
        label={"hello"}
        margin="none"
        value={value}
      >
        {props.options.map((item, i) => {
          return (
            <MenuItem
              value={item.value}
              disabled={item.disabled || false}
              key={i}
            >
              {item.option}
              {item.info}
            </MenuItem>
          );
        })}
      </Select>
      {isError === true ? (
        <span className={styles.helpText}>{props.error.message}</span>
      ) : (
          ""
        )}
    </>
  );
};
export default MaterialSingleSelect;
