import React from "react";
import PaymentButton from "./PaymentButton";
import { connect } from "react-redux";
import { getValue } from "../../utils";

const mapStateToProps = (state, ownProps) => {
  const { formReducer } = state;
  const { validForm } = formReducer;

  let type = validForm ? formReducer.values.paymentOption.selection : "Error";

  return {
    type,
    validForm,
    completeFormData: formReducer
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleSubmission: () =>
      dispatch({ type: "SUBMISSION", path: ownProps.path })
  };
};

const ConnectedPayment = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentButton);

export default ConnectedPayment;
