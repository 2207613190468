import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { Select } from "@material-ui/core";

import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion,
  InternationalClienteleQuestion,
  InternationalConsultingChargeQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class PTForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].personalTrainerPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].personalTrainerPrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Personal Trainer
        </Typography>
        <hr />
        <Row>
          <MultiSelectQuestion
            question={"Choose a Personal Trainer policy:"}
            id={"personalTrainer.selectedOption"}
            path={"personalTrainer.selectedOption"}
            options={[
              {
                option:
                  values.yoga.modo === "yes" ||
                    values.yoga.yyoga === "yes" ||
                    values.yoga.power === "yes" ||
                    values.NC.CANNP === "yes" ||
                    values.NC.CSNNAA === "yes"
                    ? "Standard Policy"
                    : "Standard Policy $" + thisPricing.standard.toFixed(2) + " premium",
                value: "standard",
                info: (
                  <>
                    {" "}
                    Has the following restrictions: <br /> No more than 2
                    clients at a time <br /> Weights cannot exceed 80lbs
                    <br /> Signed Client Waivers Required <br />{" "}
                  </>
                )
              },
              {
                option:
                  values.yoga.modo === "yes" ||
                    values.yoga.yyoga === "yes" ||
                    values.yoga.power === "yes" ||
                    values.NC.CANNP === "yes" ||
                    values.NC.CSNNAA === "yes"
                    ? "Extended Policy"
                    : "Extended Policy Premium $" + thisPricing.extended.toFixed(2),
                value: "extended",
                info: (
                  <>
                    {" "}
                    Has the following restrictions: <br /> No more than 3
                    clients at a time <br /> No weight restrictions <br />{" "}
                    Signed Client Waivers Required <br />{" "}
                  </>
                )
              }
            ]}
          />
          <YesNoQuestion
            id={"personalTrainer.bootcampyesNo"}
            path={"personalTrainer.bootcampyesNo"}
            question={"Do you offer bootcamps?"}
          />

          {values.personalTrainer.bootcampyesNo === "yes" ? (
            <TextQuestion
              id={"personalTrainer.bootcampdescription"}
              path={"personalTrainer.bootcampdescription"}
              question={"Please describe the bootcamp briefly"}
            />
          ) : (
              ""
            )}

          <br />
          <br />
          <Typography variant={"h6"}> Optional coverage</Typography>
          <br />
          <br />

          <YesNoQuestion
            id={"personalTrainer.consultingyesNo"}
            path={"personalTrainer.consultingyesNo"}
            question={
              "Do you consult or offer programs with Clients via the internet?"
            }
            info={
              "If you consult with clients via the internet (website, social media), international Telephone call, Skype, or Face Time you may purchase coverage for International Web Based Consulting"
            }
          />

          {values.personalTrainer.consultingyesNo === "yes" ? (
            <>
            <AdditionalCoverageQuestion
              coverageName="International Web based extension"
              id="personalTrainer.consultingaddCoverage"
              path="personalTrainer.consultingaddCoverage"
              type="international"
              info={
                  "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
                advise, or information, where you cannot control whether it’s a client within Canada or outside of\
                Canada, viewing your advice or content.\
                If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
                Please consider adding the International Web Based Extension to your policy.\
                Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
                must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
                client prior to a session."
              }
              price={thisPricing.webconsulting}
            />
            <InternationalClienteleQuestion
                id="personalTrainer.consultingClientele"
                type="international"
            />
            { values.personalTrainer.consultingClientele === "yes" ?
                    <strong>Please complete the form and one of our Complementary Health Brokers will contact you.</strong>
                    :  <InternationalConsultingChargeQuestion
                            id="personalTrainer.consultingCharge"
                        />
            }
            </>
          ) : (
              ""
            )}
        </Row>
      </Card>
    );
  }
}
