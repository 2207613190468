import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { Select } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import {
  CategoryA,
  CategoryB,
  CategoryC,
  CategoryRTB,
  categoryOriginal,
} from "constants/modalities";
import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion,
  CurrencyQuestion,
} from "../helpers";

export default class ApplicantInformationForm extends React.Component {
  render() {
    const { values, visible } = this.props;
    let ops =
      values.applicantInformation.modalities.indexOf("RMT") >= 0
        ? [
            ...categoryOriginal,
            ...CategoryA,
            ...CategoryB,
            ...CategoryC,
            ...CategoryRTB,
          ].filter(function (item) {
            return (
              [
                "Acupuncture/TCM",
                "Osteopathy",
                "Personal Trainer",
                "Crossfit trainer",
              ].indexOf(item.option) < 0
            );
          })
        : [
            ...categoryOriginal,
            ...CategoryA,
            ...CategoryB,
            ...CategoryC,
            ...CategoryRTB,
          ];

    if (values.applicantInformation.modalities.indexOf("NC") >= 0) {
      ops = ops.filter(function (itm) {
        return itm.option !== "Bioenergetics Intolerance Elimination";
      });
    }

    if (
      ["Ontario", "British Columbia"].indexOf(
        this.props.values.applicantInformation.province
      ) < 0 &&
      this.props.values.applicantInformation.modalities.indexOf("RMT") >= 0
    ) {
      this.props.displayGenericModal("RMT is only available in Ontario and BC");
      this.props.clearRMTBCON();
    }

    return (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Client Information
        </Typography>
        <hr />
        <TextQuestion
          id={"applicantInformation.businessName"}
          path={"applicantInformation.businessName"}
          question={"Business Name"}
        />
        {/* if small hide first/last name split */}
        <Hidden smDown>
          <Row>
            <Col xs={12} sm={6}>
              <div>
                <Typography variant={"subtitle1"} align={"left"}>
                  <strong>Name: </strong>
                </Typography>
              </div>
            </Col>
            {/* These fields use persist on unmount because these
            are two separate fields bound to the same redux state
          */}
            <Col sm={3} xs={12}>
              <TextInput
                id={"applicantInformation.firstName"}
                path={"applicantInformation.firstName"}
                placeholder={"First Name"}
                style={{ marginRight: "5px" }}
                persistOnUnmount
              />
            </Col>

            <Col sm={3} xs={12}>
              <TextInput
                id={"applicantInformation.lastName"}
                path={"applicantInformation.lastName"}
                placeholder={"Last Name"}
                style={{ marginLeft: "5px" }}
                persistOnUnmount
              />
            </Col>
          </Row>
        </Hidden>

        {/* if tablet and larget hide first/last name rows  */}
        <Hidden mdUp>
          {/* These fields use persist on unmount because these
            are two separate fields bound to the same redux state
          */}
          <TextQuestion
            id={"applicantInformation.firstName"}
            path={"applicantInformation.firstName"}
            question={"First Name"}
            persistOnUnmount
          />
          <TextQuestion
            id={"applicantInformation.lastName"}
            path={"applicantInformation.lastName"}
            question={"Last Name"}
            persistOnUnmount
          />
        </Hidden>
        <TextQuestion
          id={"applicantInformation.address"}
          path={"applicantInformation.address"}
          question={"Address"}
        />
        <TextQuestion
          id={"applicantInformation.city"}
          path={"applicantInformation.city"}
          question={"City"}
        />

        <MultiSelectQuestion
          id={"applicantInformation.province"}
          path={"applicantInformation.province"}
          question={"Province"}
          options={[
            { option: "Alberta", value: "Alberta" },
            { option: "British Columbia", value: "British Columbia" },
            { option: "Manitoba", value: "Manitoba" },
            { option: "New Brunswick", value: "New Brunswick" },
            {
              option: "Newfoundland and Labrador",
              value: "Newfoundland and Labrador",
            },
            { option: "Northwest Territories", value: "Northwest Territories" },
            { option: "Nova Scotia", value: "Nova Scotia" },
            { option: "Nunavut", value: "Nunavut" },
            { option: "Ontario", value: "Ontario" },
            { option: "Quebec", value: "Quebec" },
            {
              option: "Prince Edward Island",
              value: "Prince Edward Island",
            },
            { option: "Saskatchewan", value: "Saskatchewan" },
            { option: "Yukon", value: "Yukon" },
          ]}
        />
        <TextQuestion
          id={"applicantInformation.postalcode"}
          path={"applicantInformation.postalcode"}
          question={"Postal Code"}
        />
        <TextQuestion
          id={"applicantInformation.email"}
          path={"applicantInformation.email"}
          question={"Email"}
        />
        <TextQuestion
          id={"applicantInformation.phoneNumber"}
          path={"applicantInformation.phoneNumber"}
          question={"Phone Number"}
        />
        <TextQuestion
          id={"applicantInformation.fax"}
          path={"applicantInformation.fax"}
          question={"Fax"}
        />
        <TextQuestion
          id={"applicantInformation.businessWebsite"}
          path={"applicantInformation.businessWebsite"}
          question={"Business Website"}
        />

        <YesNoQuestion
          id={"applicantInformation.newPolicyyes_no"}
          path={"applicantInformation.newPolicyyes_no"}
          question={"I am applying for a new policy."}
        />

        {values.applicantInformation.newPolicyyes_no === "yes" ? (
          <YesNoQuestion
            id={"applicantInformation.expiring_policyyes_no"}
            path={"applicantInformation.expiring_policyyes_no"}
            question={
              "Do you currently have an active liability insurance policy with another insurance provider?"
            }
            info={
              "(Please note if you are renewing your current policy just click No)"
            }
          />
        ) : null}

        {values["applicantInformation"].expiring_policyyes_no === "yes" ? (
          <TextQuestion
            id={"applicantInformation.expiring_policypolicyNumber"}
            path={"applicantInformation.expiring_policypolicyNumber"}
            question={"Policy Number"}
          />
        ) : null}

        {values["applicantInformation"].expiring_policyyes_no === "yes" ? (
          <DateQuestion
            id={"applicantInformation.expiring_policydate"}
            path={"applicantInformation.expiring_policydate"}
            question={"Expiry Date"}
          />
        ) : null}

        {values["applicantInformation"].expiring_policyyes_no === "yes" ? (
          <YesNoQuestion
            id={"applicantInformation.expiring_policyLMI"}
            path={"applicantInformation.expiring_policyLMI"}
            question={"Is this policy provided by BrokerLink?"}
          />
        ) : null}

        {/* {values.applicantInformation.newPolicyyes_no === "no" ? ( */}
          <YesNoQuestion
            id={"applicantInformation.exisitngPolicyyes_no"}
            path={"applicantInformation.exisitngPolicyyes_no"}
            question={"I am renewing an existing policy."}
          />
        {/* ) : null} */}

        {values.applicantInformation.newPolicyyes_no === "yes" ? (
          <DateQuestion
            id={"applicantInformation.expiring_policydifferentDate"}
            path={"applicantInformation.expiring_policydifferentDate"}
            question={
              "If you wish a different start date indicate here. If not, your policy will start the next day at 12:01 AM"
            }
            info={
              "(Please note this is only applies if you are Applying for a new policy)"
            }
          />
        ) : null}

        <br />
        <br />
        <Typography variant={"h6"}>Modalities</Typography>
        <br />
        <AutocompleteQuestion
          id={"applicantInformation.modalities"}
          path={"applicantInformation.modalities"}
          question={"Modalities"}
          placeholder={"Modalities"}
          info={
            "Please choose your main Modality (service you provide) from the drop down list, you can also choose other to add more modalities."
          }
          options={ops.sort((a, b) => {
            let comparison = 0;
            if (a.option > b.option) {
              comparison = 1;
            } else if (a.option < b.option) {
              comparison = -1;
            }
            return comparison;
          })}
        />

        {values.applicantInformation.modalities.indexOf("Other") >= 0 ? (
          <TextQuestion
            id={"applicantInformation.otherModalities"}
            path={"applicantInformation.otherModalities"}
            question={"Other modalities: "}
          />
        ) : null}

        <YesNoQuestion
          id={"applicantInformation.trainingCertificate"}
          path={"applicantInformation.trainingCertificate"}
          question={
            "Do you have a training certificate for all the modalities you are practicing?"
          }
        />

        {values.applicantInformation.trainingCertificate === "yes" ? (
          <UploadQuestion
            fileCollectionName={"modalities"}
            question={"Please upload a copy of your certificates of training"}
            info={
              "Please note this is only required if you are adding a new modality or are a new client. This is not required if you are renewing an existing policy."
            }
          />
        ) : null}

        <YesNoQuestion
          id={"applicantInformation.sellProductsyesNo"}
          path={"applicantInformation.sellProductsyesNo"}
          question={"Do you sell any products?"}
        />

        {values.applicantInformation.sellProductsyesNo === "yes" ? (
          <TextQuestion
            id={"applicantInformation.sellProductsdescription"}
            path={"applicantInformation.sellProductsdescription"}
            question={"Please list the products you sell."}
          />
        ) : null}

        <YesNoQuestion
          id={"applicantInformation.manufactureProductsyesNo"}
          path={"applicantInformation.manufactureProductsyesNo"}
          question={"Do you manufacture any products?"}
        />

        {values.applicantInformation.manufactureProductsyesNo === "yes" ? (
            <>
          <TextQuestion
            id={"applicantInformation.manufactureProductsdescription"}
            path={"applicantInformation.manufactureProductsdescription"}
            question={"Please list the products you manufacture."}
          />
           <YesNoQuestion
                question={"Do you sell these products either online or in a store?"}
                id={"applicantInformation.manufactureProductsToBeSold"}
                path={"applicantInformation.manufactureProductsToBeSold"}
            />
          </>
        ) : null}

        <TextQuestion
          id={"applicantInformation.grossSales"}
          path={"applicantInformation.grossSales"}
          question={
            "Please indicate your estimated gross annual income for the next 12 months:"
          }
        />
        <CurrencyQuestion
          id={"applicantInformation.equipmentCost"}
          path={"applicantInformation.equipmentCost"}
          question={
            "What is the estimated value of the equipment used for your business?"
          }
          info={"Generally refers to all contents usual to your business, including furniture, machinery, tools, appliances. For example: computers, massage tables, laser machine, etc."}
        />
        <CurrencyQuestion
          id={"applicantInformation.stockCost"}
          path={"applicantInformation.stockCost"}
          question={
            "What is the estimated value of the stock used for your business?"
          }
          info={"Generally refers to all merchandise usual to your business. For example, massage oils, printing papers, advertising materials, etc"}
        />
        {values.applicantInformation.stockCost > 1
            ?
                (
                <>
                <TextQuestion
                    id={"applicantInformation.stockContentsDescription"}
                    path={"applicantInformation.stockContentsDescription"}
                    question={
                        "Provide a brief description of contents"
                    }
                />
                </>
                )
            :null
        }

        <YesNoQuestion
            id={"applicantInformation.independentspaClinicStudioOwner"}
            path={"applicantInformation.independentspaClinicStudioOwner"}
            question={"Are you a Commercial Clinic, Spa or studio owner?"}
        />

        {values.applicantInformation.independentspaClinicStudioOwner === "no" ? (
            <YesNoQuestion
            id={"applicantInformation.independentyesNo"}
            path={"applicantInformation.independentyesNo"}
            question={
                "Are you an employee / an independent contractor / or a sub-contractor for a clinic, spa or studio?"
            }
            />
        ) : null}

        {values.applicantInformation.independentyesNo === "no" ? (
          <YesNoQuestion
            id={"applicantInformation.independentwfh"}
            path={"applicantInformation.independentwfh"}
            question={"Do you work from your home?"}
          />
        ) : null}

        {values.applicantInformation.independentwfh === "yes" ? (
          <YesNoQuestion
            id={"applicantInformation.independentsubc"}
            path={"applicantInformation.independentsubc"}
            question={"Do you have any sub-contractors or employees?"}
          />
        ) : null}

        {values.applicantInformation.independentsubc === "yes" ? (
          <TextQuestion
            id={"applicantInformation.numbersubc"}
            path={"applicantInformation.numbersubc"}
            question={
              "Please advise how many subcontractors/employees are currently working with you"
            }
          />
        ) : null}

        {values.applicantInformation.independentsubc === "no" ? (
          <TextQuestion
            id={"applicantInformation.independentsituation"}
            path={"applicantInformation.independentsituation"}
            question={"Please explain your situation: "}
          />
        ) : null}


        {values.applicantInformation.independentspaClinicStudioOwner ===
        "no" ? (
          <TextQuestion
            id={"applicantInformation.independentbusinessStructure"}
            path={"applicantInformation.independentbusinessStructure"}
            question={"Other- please explain your business structure"}
          />
        ) : null}

        {values.applicantInformation.independentspaClinicStudioOwner ===
        "yes" ? (
          <YesNoQuestion
            id={"applicantInformation.independentspacSub"}
            path={"applicantInformation.independentspacSub"}
            question={
              "Do you have any sub-contractors or employees working at your clinic?"
            }
          />
        ) : null}

        {values.applicantInformation.independentspaClinicStudioOwner ===
          "yes" && values.applicantInformation.independentspacSub === "yes" ? (
          <NumberQuestion
            id={"applicantInformation.independentnumberOfEmployees"}
            path={"applicantInformation.independentnumberOfEmployees"}
            question={
              "How many sub contractors and how many Employees do you have working at your clinic?"
            }
          />
        ) : null}

        {values.applicantInformation.independentspaClinicStudioOwner ===
          "yes" && values.applicantInformation.independentspacSub === "yes" ? (
          <TextQuestion
            id={"applicantInformation.independentemployeeModalities"}
            path={"applicantInformation.independentemployeeModalities"}
            question={"Please list the modalities they practice"}
          />
        ) : null}

        {values.applicantInformation.independentspaClinicStudioOwner ===
        "yes" ? (
          <MultiSelectQuestion
            id={"applicantInformation.independentrent"}
            path={"applicantInformation.independentrent"}
            question={
              "Do you rent or own the space you run your business out of?"
            }
            options={[
              { option: "Rent", value: "rent" },
              { option: "Own", value: "own" },
            ]}
          />
        ) : null}

        {values.applicantInformation.independentspaClinicStudioOwner ===
        "yes" ? (
          <TextQuestion
            id={"applicantInformation.independentclinicLocation"}
            path={"applicantInformation.independentclinicLocation"}
            question={"Please provide the address of your business location"}
          />
        ) : null}

        {values.applicantInformation.independentspaClinicStudioOwner ===
        "yes" ? (
          <TextQuestion
            id={"applicantInformation.independentclinicSize"}
            path={"applicantInformation.independentclinicSize"}
            question={"Please indicate the size of your clinic is SQFT."}
            info={
              "(This is to verify  the  coverages under this policy  is sufficient enough for your business)"
            }
          />
        ) : null}

        {values.applicantInformation.independentspaClinicStudioOwner ===
        "yes" ? (
          <YesNoQuestion
            id={"applicantInformation.independentSublet"}
            path={"applicantInformation.independentSublet"}
            question={
              "Do you sub-let, sub-lease or rent out any portion of your space to others?"
            }
          />
        ) : null}

        {values.applicantInformation.independentspaClinicStudioOwner ===
        "yes" ? (
          <YesNoQuestion
            id={"applicantInformation.independentpartner"}
            path={"applicantInformation.independentpartner"}
            question={"Do you have a business partner?"}
          />
        ) : null}

        {values.applicantInformation.independentspaClinicStudioOwner ===
        "yes" ? (
          <YesNoQuestion
            id={"applicantInformation.independentseparatePolicy"}
            path={"applicantInformation.independentseparatePolicy"}
            question={
              "Do you have a separate policy for your business space?"
            }
          />
        ) : null}
        {values.applicantInformation.independentseparatePolicy==="yes"?(
            <UploadQuestion
            fileCollectionName={"modalities"}
            question={"Please upload a copy of your policy"}
            info=""
          />
        ): null}

        {values.applicantInformation.independentseparatePolicy ===
        "yes" ? (
          <YesNoQuestion
            id={"applicantInformation.independentcommercialPolicy"}
            path={"applicantInformation.independentcommercialPolicy"}
            question={
              "Do you have a commercial policy which includes Contents coverage, Commercial General Liability &  Professional Liability (E&O) for your business Entity?"
            }
          />
        ) : null}
      </Card>
    );
  }
}
