import React from "react";
import Card from "components/Card";
import Typography from "@material-ui/core/Typography";
import {
  MultiSelectQuestion,
  DateQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion
} from "../helpers";
import { acupuncture } from "constants/form";
import { pricing } from "constants/pricing";

export default class Acupuncture extends React.Component {
  render() {
    const { values, visible } = this.props;
    // client didn't ask for this but let this just in case they require it in the future
    // if (values.acupuncture.servicesOutsideCanada === "yes") {
    //   this.props.displayGenericModal(
    //     "Please note we only provide policies within Canada"
    //   );
    //   this.props.clearForm();
    // }

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length-1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          if (values.acupuncture.associationMember === "yes") {
            thisPricing = pricing[i].acupuncturePrices.association;
          } else if (values.applicantInformation.province === "Ontario") {
            thisPricing = pricing[i].acupuncturePrices.ontario;
          } else {
            thisPricing = pricing[i].acupuncturePrices.other;
          }
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          if (values.acupuncture.associationMember === "yes") {
            thisPricing = pricing[i].acupuncturePrices.association;
          } else if (values.applicantInformation.province === "Ontario") {
            thisPricing = pricing[i].acupuncturePrices.ontario;
          } else {
            thisPricing = pricing[i].acupuncturePrices.other;
          }
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Acupuncture/TCM
        </Typography>
        <hr />

            {/* {values.applicantInformation.province === "Ontario" || values.applicantInformation.province === "British Columbia" ? ( */}
        <YesNoQuestion
                    question={"Do you currently have an active Registered Massage Therapist policy with BrokerLink? "}
          info={""
          }
          id={"acupuncture.rmtPolicy"}
          path={"acupuncture.rmtPolicy"}
        />
        {/* ) : null } */}

        {values.acupuncture.rmtPolicy === "yes"?(
        <UploadQuestion
         question={"Please provide your policy number or upload certificate of insurance "}
         fileCollectionName={"acupunctureRMTPolicyFiles"}
        />
        )
        :null}

        {values.acupuncture.associationMember !== "yes" && values.acupuncture.rmtPolicy === "yes" && (values.applicantInformation.province === "Ontario" || values.applicantInformation.province === "British Columbia") ? (
        <MultiSelectQuestion
          question={"Please choose from the following options."}
          info={"(Each options with 5,000,000 aggregate a year)"}
          id={"acupuncture.selectedOption"}
          path={"acupuncture.selectedOption"}
          options={[
            {
              option:
                "$" + thisPricing.rmt1mil.toFixed(2) + " NEW Acupuncture/TCM $1,000,000 Professional Liability ONLY",
              value: "rmt1mil"
            },
            {
              option:
              "$" + thisPricing.rmt2mil.toFixed(2) + " NEW Acupuncture/TCM $2,000,000 Professional Liability ONLY",
              value: "rmt2mil"
            },
            {
              option:
              "$" + thisPricing.rmt5mil.toFixed(2) + " NEW Acupuncture/TCM $5,000,000 Professional Liability ONLY",
              value: "rmt5mil"
            },
          ]}
        />
        ) : null }

        {values.acupuncture.associationMember === "yes" && values.acupuncture.rmtPolicy === "yes" && values.applicantInformation.province === "Ontario" ? (
        <MultiSelectQuestion
          question={"Please choose from the following options."}
          info={"(Each options with 5,000,000 aggregate a year)"}
          id={"acupuncture.selectedOption"}
          path={"acupuncture.selectedOption"}
          options={[
            {
              option:
                "$" + thisPricing.prof1mil.toFixed(2) + " NEW Acupuncture/TCM $1,000,000 Professional Liability ONLY",
              value: "prof1mil"
            },
            {
              option:
              "$" + thisPricing.prof2mil.toFixed(2) + " NEW Acupuncture/TCM $2,000,000 Professional Liability ONLY",
              value: "prof2mil"
            },
            {
              option:
              "$" + thisPricing.prof5mil.toFixed(2) + " NEW Acupuncture/TCM $5,000,000 Professional Liability ONLY",
              value: "prof5mil"
            },
          ]}
        />
        ) : null }

        {!(values.acupuncture.rmtPolicy === "yes" && values.applicantInformation.province === "Ontario") ? (
          <MultiSelectQuestion
            question={"Please choose from the following options."}
            info={"(Each options with 5,000,000 aggregate a year)"}
            id={"acupuncture.selectedOption"}
            path={"acupuncture.selectedOption"}
            options={[
              {
                option:
                  "$" + thisPricing.prof1mil.toFixed(2) + " NEW Acupuncture/TCM $1,000,000 Professional Liability ONLY",
                value: "prof1mil"
              },
              {
                option:
                "$" + thisPricing.prof2mil.toFixed(2) + " NEW Acupuncture/TCM $2,000,000 Professional Liability ONLY",
                value: "prof2mil"
              },
              {
                option:
                "$" + thisPricing.prof5mil.toFixed(2) + " NEW Acupuncture/TCM $5,000,000 Professional Liability ONLY",
                value: "prof5mil"
              },
              {
                option:
                "$" + thisPricing.profcomm1mil.toFixed(2) + " NEW Acupuncture/TCM $1,000,000 Professional Liability & Commercial General Liability",
                value: "profcomm1mil"
              },
              {
                option:
                "$" + thisPricing.profcomm2mil.toFixed(2) + " NEW Acupuncture/TCM $2,000,000 Professional Liability & Commercial General Liability",
                value: "profcomm2mil"
              },
              {
                option:
                "$" + thisPricing.profcomm5mil.toFixed(2) + " NEW Acupuncture/TCM $5,000,000 Professional Liability & Commercial General Liability",
                value: "profcomm5mil"
              },
              {
                option:
                "$" + thisPricing.profcommpers1mil.toFixed(2) + " NEW Acupuncture/TCM $1,000,000 Professional Liability & Commercial General Liability & $10,000 Personal Protection Package",
                value: "profcommpers1mil"
              },
              {
                option:
                "$" + thisPricing.profcommpers2mil.toFixed(2) + " NEW Acupuncture/TCM $2,000,000 Professional Liability & Commercial General Liability & $10,000 Personal Protection Package",
                value: "profcommpers2mil"
              },
              {
                option:
                "$" + thisPricing.profcommpers5mil.toFixed(2) + " NEW Acupuncture/TCM $5,000,000 Professional Liability & Commercial General Liability & $10,000 Personal Protection Package",
                value: "profcommpers5mil"
              },
            ]}
          />
          ) : null}

        <UploadQuestion
          question={"Please upload your training certificate "}
          fileCollectionName={"acupunctureFiles"}
        />

        <YesNoQuestion
          question={"Do you provide services outside of Canada? "}
          info={
            "(Please note this policy will only provide coverage while practicing within Canada.)"
          }
          id={"acupuncture.servicesOutsideCanada"}
          path={"acupuncture.servicesOutsideCanada"}
        />

        <YesNoQuestion
          question={
            "Are you a member of an Association?*"
          }
          id={"acupuncture.associationMember"}
          path={"acupuncture.associationMember"}
        />

        {values.acupuncture.associationMember === "yes" ? (
        <>
          <MultiSelectQuestion
            question={"Please choose one of the Acupuncture Associations you are a current member of:*"}
            info={"We have a special discount rate for the following Associations, you must be an active member with one of these associations in order to qualify for the discount."}
            id={"acupuncture.associationOption"}
            path={"acupuncture.associationOption"}
            options={[
              {
                option: "Canadian Society of Chinese Medicine and Acupuncture",
                value: "CSCMA"
              },
              {
                option: "Chinese Medicine and Acupuncture Association of Canada",
                value: "CMAAC"
              },
              {
                option: "Ontario Acupuncture Association and Traditional Chinese Medicine",
                value: "OAATCM"
              },
              {
                option: "Chinese Medicine and Acupuncture Association of Newfoundland & Labrador",
                value: "CMAANL"
              },
              {
                option: "Provincial Traditional Chinese Medicine and Acupuncture Society, Inc. (PTCMAAS)",
                value: "PTCMAAS"
              },
              {
                option: "Traditional Chinese Medicine Ontario",
                value: "TCMO"
              },
              // {
              //   option: "Other",
              //   value: "Other"
              // },
            ]}
          />
          <UploadQuestion
            question={" Please upload a recent copy of your  membership or receipt of payment for the Association fees."}
            fileCollectionName={"acupunctureMembershipFiles"}
            />
        </>
        ) : null}

        <YesNoQuestion
          question={
            "Are you an active member, in good standing with the CTCMPAO / CTCMA / CAAA / CTCMPANL?*"
          }
          id={"acupuncture.activeMember"}
          path={"acupuncture.activeMember"}
        />

        {values.acupuncture.activeMember === "yes" ? (
          <TextQuestion
            question={"Please provide your registration number?"}
            id={"acupuncture.activeMemberNumber"}
            path={"acupuncture.activeMemberNumber"}
          />
        ) : null}

        <YesNoQuestion
          question={
            "Are you in the proccess of applying membership with the CTCMPAO / CTCMA / CAAA / CTCMPANL?*"
          }
          id={"acupuncture.activeMemberApplying"}
          path={"acupuncture.activeMemberApplying"}
        />

        <YesNoQuestion
          question={
            "Do you currently have Acupuncture/TCM Professional Liability Insurance?*"
          }
          id={"acupuncture.currentPolicy"}
          path={"acupuncture.currentPolicy"}
        />

        {values.acupuncture.currentPolicy === "yes" ? (
          <>
            <TextQuestion
              question={"Company Name*"}
              id={"acupuncture.currentPolicyName"}
              path={"acupuncture.currentPolicyName"}
            />
            <TextQuestion
              question={"Policy Number*"}
              id={"acupuncture.currentPolicyNumber"}
              path={"acupuncture.currentPolicyNumber"}
            />
            <DateQuestion
              question={"What is your retroactive date?*"}
              id={"acupuncture.currentPolicyRetroactive"}
              path={"acupuncture.currentPolicyRetroactive"}
            />
            <DateQuestion
              question={"What is your expiry date?*"}
              id={"acupuncture.currentPolicyExpiry"}
              path={"acupuncture.currentPolicyExpiry"}
            />

            <UploadQuestion
              question={"Please upload your training certificate "}
              fileCollectionName={"acupunctureFiles2"}
            />
          </>
        ) : null}

        <YesNoQuestion
          question={"Do you sell products?"}
          id={"acupuncture.sellProducts"}
          path={"acupuncture.sellProducts"}
        />

        {values.acupuncture.sellProducts === "yes" ? (
          <TextQuestion
            question={"Describe the products you sell:"}
            id={"acupuncture.sellProductsDescription"}
            path={"acupuncture.sellProductsDescription"}
          />
        ) : null}

        <YesNoQuestion
          question={"Do you manufacture products?"}
          id={"acupuncture.manufactureProducts"}
          path={"acupuncture.manufactureProducts"}
        />

        {values.acupuncture.manufactureProducts === "yes" ? (
            <>
            <TextQuestion
                question={"Describe the products you manufacture:"}
                id={"acupuncture.manufactureProductsDescription"}
                path={"acupuncture.manufactureProductsDescription"}
            />
            <YesNoQuestion
                question={"Do you sell these products either online or in a store?"}
                id={"acupuncture.manufactureProductsToBeSold"}
                path={"acupuncture.manufactureProductsToBeSold"}
            />
          </>
        ) : null}

        <YesNoQuestion
          question={"Do you offer TCM / Herbal Medicine in a pill form?"}
          id={"acupuncture.TCMPill"}
          path={"acupuncture.TCMPill"}
        />

        {values.acupuncture.TCMPill === "yes" ? (
          <>
            <YesNoQuestion
              question={"Do you manufacture / repack / relabel the pills?"}
              id={"acupuncture.TCMPillManufacture"}
              path={"acupuncture.TCMPillManufacture"}
            />
            <YesNoQuestion
              question={
                "Are the Pills being Manufactured in a Canadian Goverenment approved Facility"
              }
              id={"acupuncture.TCMPillManufactureApproved"}
              path={"acupuncture.TCMPillManufactureApproved"}
            />
            <YesNoQuestion
              question={
                "Are the Herbs being tested prior to being composed in the pill form"
              }
              id={"acupuncture.TCMPillManufactureHerbs"}
              path={"acupuncture.TCMPillManufactureHerbs"}
            />
            <YesNoQuestion
              question={
                "Are the Herbs purchased in Canada or by a Canadian certified supplier "
              }
              id={"acupuncture.TCMPillManufatureCanada"}
              path={"acupuncture.TCMPillManufatureCanada"}
            />
          </>
        ) : null}
         <YesNoQuestion
          id={"acupuncture.laser"}
          path={"acupuncture.laser"}
          question={"Do you offer any type of Laser therapy (Use a Laser machine)?"}
        />
      { values.acupuncture.laser === "yes"
        ? (<YesNoQuestion
          id={"acupuncture.coldOrLowLevelLasers"}
          path={"acupuncture.coldOrLowLevelLasers"}
          question={
          "Is this a Cold lasers or low-level lasers machine?"
          }
        />)
        : null}
        { values.acupuncture.coldOrLowLevelLasers === "no"
        ? (<YesNoQuestion
          id={"acupuncture.ownLaserMachine"}
          path={"acupuncture.ownLaserMachine"}
          question={
          "Do you own the Laser machine?"
          }
        />)
        : null}
        { values.acupuncture.ownLaserMachine === "yes"
            ? (
            <>
            <TextQuestion
                id={"acupuncture.laserMachineDetails"}
                path={"acupuncture.laserMachineDetails"}
                question={
                "Please provide us with the Type of laser machine, make, model, S/N"
                }
            />
            <TextQuestion
                id={"acupuncture.laserMachineAge"}
                path={"acupuncture.laserMachineAge"}
                question={
                "Please provide us with the age of machine, where was it manufactured & value"
                }
            />
            <TextQuestion
                id={"acupuncture.laserMachineMobile"}
                path={"acupuncture.laserMachineMobile"}
                question={
                "Please advise if this laser equipment will be used in the clinic only or in different locations / mobile"
                }
            />
            <YesNoQuestion
                id={"acupuncture.laserMachineWaivers"}
                path={"acupuncture.laserMachineWaivers"}
                question={
                "Are waivers being signed by each participant?"
                }
            />
            <TextQuestion
                id={"acupuncture.laserMachineMaintenance"}
                path={"acupuncture.laserMachineMaintenance"}
                question={
                "What is the maintenance for this equipment?"
                }
            />
            <TextQuestion
                id={"acupuncture.laserMachineLeasedOwned"}
                path={"acupuncture.laserMachineLeasedOwned"}
                question={
                "Is the equipment leased or owned by you?"
                }
            />
            <TextQuestion
                id={"acupuncture.laserMachineUse"}
                path={"acupuncture.laserMachineUse"}
                question={
                "What is the machine used for?"
                }
            />
            <YesNoQuestion
                id={"acupuncture.laserMachineUsedBy"}
                path={"acupuncture.laserMachineUsedBy"}
                question={
                "Will there be other practitioners operating the equipment?"
                }
            />
            <YesNoQuestion
                id={"acupuncture.laserMachineTraining"}
                path={"acupuncture.laserMachineTraining"}
                question={
                "Are you properly trained for operations of this laser machine?"
                }
            />
            
            </>)
        : null}
        {values.acupuncture.coldOrLowLevelLasers === "yes"
        ?  (<UploadQuestion
            question={"Please upload training certificate for the use of the machine"}
            fileCollectionName={"acupunctureLaserMachineFiles"}
           />)
        :null}
      </Card>
    );
  }
}
