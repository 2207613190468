import axios from "axios";

const sendForm = body =>
    axios.post("https://server.ineedapolicy.com/charges", body, {
    //axios.post("https://apply-server.ineedapolicy.com/charges", body, {
    headers: {
            "Content-Type": "application/json"
        }
    });

export default { sendForm };
