import React, { Component } from 'react';
/*Import components relevant to this form */
import InputField from './components/InputField';
import {
    Typography, Grid, InputAdornment
} from '@material-ui/core';


class FormContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "",
            emailAddress: "",
            phoneNumber: "",
            paymentAmount: "",
            errorEmail: false,
            errorAmount: false
        }
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        //this.handleClearForm = this.handleClearForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleSubmitForm() {

    }
    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        switch (e.target.name) {
            case 'emailAddress': {
                if (!this.validateEmail(e.target.value)) {
                    this.setState({ errorEmail: true });
                    this.props.errorCallback(this.state.errorEmail);
                } else {
                    this.setState({ errorEmail: false });
                    this.props.emailCallback(e.target.value);
                    this.props.errorCallback(this.state.errorEmail);
                }

                break;
            }
            case 'paymentAmount': {
                if (isNaN(e.target.value)) {
                    this.setState({ errorAmount: true });
                    this.props.errorCallback(this.state.errorAmount);
                } else {
                    this.setState({ errorAmount: false });
                    this.props.amountCallback(e.target.value);
                    this.props.errorCallback(this.state.errorAmount);
                }

                break;
            }
        }

    }
    render() {
        return (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
            >

                <Grid item xs={12} sm={3}>
                    <Typography
                        variant={"body1"}
                        style={{
                            flex: 0.5,
                            color: "#1B504C",
                            fontWeight: "bold"
                        }} >
                        Full Name
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField
                        value={this.state.fullName}
                        name={'fullName'}
                        id={'fullName'}
                        onChange={this.handleInputChange}
                        helperText={this.state.helperText}
                    />
                </Grid>
                <Grid item sm={3} md={3} lg={3}></Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        variant={"body1"}
                        style={{
                            flex: 0.5,
                            color: "#1B504C",
                            fontWeight: "bold"
                        }} >
                        Email Address
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField
                        value={this.state.emailAddress}
                        name={'emailAddress'}
                        id={'emailAddress'}
                        onChange={this.handleInputChange}
                        helperText={this.state.errorEmail ? '** Please enter a valid email' :
                            '* This email address must match the email address we have on file.'}
                        error={this.state.errorEmail}
                    />
                </Grid>
                <Grid item sm={3} md={3} lg={3}></Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        variant={"body1"}
                        style={{
                            flex: 0.5,
                            color: "#1B504C",
                            fontWeight: "bold"
                        }} >
                        Phone Number
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField
                        value={this.state.phoneNumber}
                        name={'phoneNumber'}
                        id={'phoneNumber'}
                        onChange={this.handleInputChange}
                    />
                </Grid>
                <Grid item sm={3} md={3} lg={3}></Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        variant={"body1"}
                        style={{
                            flex: 0.5,
                            color: "#1B504C",
                            fontWeight: "bold"
                        }} >
                        Payment Amount
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputField
                        value={this.state.paymentAmount}
                        name={'paymentAmount'}
                        id={'paymentAmount'}
                        onChange={this.handleInputChange}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        helperText={this.state.errorAmount ? '** Please enter a valid amount ' : '* Please fill out the amount as stated in the email you received.'}
                        error={this.state.errorAmount}
                    />
                </Grid>
                <Grid item sm={3} md={3} lg={3}></Grid>
            </Grid >
        )
    }


}

export default FormContainer;