import { getValue } from "../../utils";
import TextInput from "./TextInput";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;
  //
  const value = getValue(formReducer.values, ownProps.path);
  const valid = getValue(formReducer.valid, ownProps.path);
  if (!ownProps.path) {
    throw new Error("no path " + ownProps.id, ownProps);
  }
  return {
    error: {
      value: formReducer.submissionAttempted && !valid,
      message: "* This field is required"
    },
    value: value
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (path, value) => {
      dispatch({ type: "UPDATE_FORM", path: ownProps.path, value });
    },
    handleMount: () => {
      dispatch({ type: "MOUNTED", path: ownProps.path });
    },
    handleUnmount: value => {
      // Persist on unmount will allow textfields to be
      // persisted in redux state in case of an unmount
      // Useful for multiple fields attached to the same value
      if (ownProps.persistOnUnmount) return;
      dispatch({ type: "UNMOUNTED", path: ownProps.path, value });
    }
  };
};

const ConnectedTextInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(TextInput);

export default ConnectedTextInput;
