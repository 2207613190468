import { connect } from "react-redux";
import Success from "./Success";
const mapStateToProps = state => {
  const { formReducer } = state;

  return {
    form: formReducer.values,
    successFullSubmission: formReducer.successFullSubmission,
    values: formReducer.values, visible: formReducer.visible
  };
};

export default connect(
  mapStateToProps,
  null
)(Success);
