import React, { useState, useEffect } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import styles from "./YesNoInput.module.css";

const YesNoInput = props => {
  const { id, handleChange, error, handleMount, handleUnmount } = props;
  const [value, setValue] = useState(props.value);
  const isError = error && error.value === true;

  useEffect(() => {
    handleMount();
    return () => {
      handleUnmount();
    };
  }, []);

  return (
    <>
      <RadioGroup
        id={id}
        aria-label="yesno"
        name={id}
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          backgroundColor: !isError ? "white" : "rgb(244, 67, 54, 0.1)",
          borderRadius: 10,
          paddingBottom: 5,
          paddingTop: 5,
          marginTop: 15
        }}
        onChange={e => {
          setValue(e.target.value);
          handleChange(id, e.target.value);
        }}
      >
        <FormControlLabel
          value="yes"
          control={
            <Radio
              color={"default"}
              classes={
                isError ? { root: styles.radio, checked: styles.checked } : {}
              }
            />
          }
          label="Yes"
          // classes={isError ? { root: styles.red } : {}}
        />
        <FormControlLabel
          value="no"
          control={
            <Radio
              color={"default"}
              classes={
                isError ? { root: styles.radio, checked: styles.checked } : {}
              }
            />
          }
          label="No"
          // classes={isError ? { root: styles.red } : {}}
        />
      </RadioGroup>
      {isError ? (
        <div style={{ flex: "1 0 50%" }}>
          <span className={styles.helpText}>{props.error.message}</span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default YesNoInput;
