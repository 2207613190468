import values from "./values";
import validation from "./validation";
const aromatherapy = {
  CFA: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are you a member of the Canadian Federation of Aromatherapists (CFA)?",
    info: ""
  },
  BCAPA: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question:
      "Are you a member with the British Columbia Associations of Practicing Aromatherapists (BCAPA)?",
    info: ""
  },
  consultingyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you consult or offer programs with Clients via the internet?",
    info: ""
  },
  consultingaddCoverage: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Add International Web Based Consulting?",
    info:
    "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
    advise, or information, where you cannot control whether it’s a client within Canada or outside of\
    Canada, viewing your advice or content.\
    If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
    Please consider adding the International Web Based Extension to your policy.\
    Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
    must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
    client prior to a session."
  },
  manufactureyesNo: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Do you manufacture any products?",
    info: ""
  },
  manufacturedescription: {
    defaultvalue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    question: "Please describe the products that you are manufacturing.",
    info: ""
  },
  consultingClientele:{
    defaultValue: values.EMPTY_STRING,
    validation: validation.NOT_EMPTY_STRING,
    info: "",
    question: "Is your clientele more than 50% outside of Canada"
    },
  consultingCharge:{
        defaultValue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        info: "",
        question: "Add International coverage to your policy, for an additional $"
    }
};

export default aromatherapy;
