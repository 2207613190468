import React from "react";
import Form from "./PTForm";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;

  if (
    ownProps.visibility === undefined ||
    typeof ownProps.visibility !== "function"
  ) {
    return {
      visible:
        formReducer.values.applicantInformation.modalities.indexOf(
          "Personal Trainer"
        ) >= 0,
      values: formReducer.values
    };
  } else {
    return {
      visible:
        formReducer.values.applicantInformation.modalities.indexOf(
          "Personal Trainer"
        ) >= 0,
      values: formReducer.values
    };
  }
};

const ConnectedForm = connect(
  mapStateToProps,
  null
)(Form);

export default ConnectedForm;
