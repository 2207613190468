import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { Select } from "@material-ui/core";
import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class PrivacyBreachForm extends React.Component {
  render() {
    const { values, visible } = this.props;
    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].privacyBreach;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].privacyBreach;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Privacy Breach/Cyber Liability Coverage
        </Typography>

        <Typography variant={"caption"}>
          If your routine business activities include handling or processing payments, storing client information, employee records, or other personal information defined by provincial or federal law you may purchase coverage for Privacy Breach/Cyber Liability. A privacy breach can include the loss, theft, unauthorized access to or use of personal customer or employee information. This data can include: Social Insurance Number; bank account number, credit or debit card information; driver’s licence number; medical diagnosis, patient history and medications; and, other personal information.
        </Typography>

        <hr />
        {values.applicantInformation.modalities.includes('Acupuncture/TCM') //For Accupuncture additional coverage question shouldn't show
        ? ( <YesNoQuestion
            question={"Do you wish to be contacted to receive a quote for Privacy Breach coverage?"}
            id={"privacyBreach.quote"}
            path={"privacyBreach.quote"}
          />)
        :
        (
        <>
        <AdditionalCoverageQuestion
            coverageName="Privacy Breach/Cyber Liability Coverage"
            id="privacyBreach.yesno"
            path={"privacyBreach.yesno"}
            info={
              "To add Privacy Breach Liability coverage, the additional premium is $" + thisPricing.premium.toFixed(2) + " annually and includes $25,000 in First-Party coverage for the following: Remediation expenses to cover various costs (such as notifying customers and employees; and, credit and fraud monitoring expenses); Business interruption to cover loss of income related to the breach, and necessary extra expenses; and, Legal expense coverage for certain legal fees and defence costs incurred as a result of a covered breach. Please select here to add this coverage to your policy."
            }
            price={thisPricing.premium}
          />
          <Typography variant={"subtitle1"}>
            *Higher limits of coverage are available. Please consult with a Complementary Health Account Manager to further discuss your needs.
          </Typography>
        </>
        )}
      </Card>
    );
  }
}
