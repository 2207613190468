import React from "react";
import Form from "./Disclaimer";
import { connect } from "react-redux";
import { modalitiesRTB } from "constants/modalities";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;

  return {
    visible: true,
    values: formReducer.values,
    rtb: formReducer.pricing.total.premium === 0 
    || (formReducer.values["applicantInformation"].province === "Quebec" && formReducer.values["applicantInformation"].exisitngPolicyyes_no === "yes")
     //This will be referred to broker as per Jan 2024 
  };
};

// const mapDispatchToProps = (dispatch)=>{
//   return {
//     rtb
//   }
// }

const ConnectedForm = connect(mapStateToProps, null)(Form);

export default ConnectedForm;
