import values from "./values";
import validation from "./validation";
const pilates = {
    equipment: {
        defaultvalue: values.EMPTY_STRING,
        validation: validation.NOT_EMPTY_STRING,
        question: "Do you teach Equipment Pilates?"
    },
    trx: {
            defaultValue: values.EMPTY_STRING,
            validation: validation.NOT_EMPTY_STRING,
            question: "Do you use TRX or Ropes in your practice?"
        },
        trxSuspension: {
            defaultValue: values.EMPTY_STRING,
            validation: validation.NOT_EMPTY_STRING,
            info: "IMPORTANT EXCLUSION: Please note this policy prohibits full body suspension.  If you wish to proceed please confirm you do not allow clients to be fully suspended while using the ropes or TRX and you understand that in the event of an accident due to full body suspension, there is no coverage under this policy.",
            question: "Do you wish to proceed?"
        },
        consultingaddCoverage: {
            defaultvalue: values.EMPTY_STRING,
            validation: validation.NOT_EMPTY_STRING,
            info:  "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
            advise, or information, where you cannot control whether it’s a client within Canada or outside of\
            Canada, viewing your advice or content.\
            If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
            Please consider adding the International Web Based Extension to your policy.\
            Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
            must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
            client prior to a session.",
            question: "Do you wish to add International Web Based Consulting to your policy?"
        },
        consultingClientele:{
            defaultValue: values.EMPTY_STRING,
            validation: validation.NOT_EMPTY_STRING,
            info: "",
            question: "Is your clientele more than 50% outside of Canada"
            },
          consultingCharge:{
                defaultValue: values.EMPTY_STRING,
                validation: validation.NOT_EMPTY_STRING,
                info: "",
                question: "Add International coverage to your policy, for an additional $"
            }

};

export default pilates;
