import React from "react";
import Card from "components/Card";
import Typography from "@material-ui/core/Typography";
import {
  YesNoQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class Crossfit extends React.Component {
  render() {
    const { values, visible } = this.props;

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length-1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].crossfitPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].crossfitPrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
        CrossFit Trainers
        </Typography>
        <hr />

        <YesNoQuestion
          id={"crossfit.studio"}
          path={"crossfit.studio"}
          question={"Are you working at a CrossFit Studio? "}
        />
        {values.crossfit.studio === "yes"
          ? "Please note you are only insured while working in a CrossFit studio, this policy does not permit you to work outside of a CrossFit Studio.  Please contact our office if this does not fit your business structure. "
          : "Please note in order to qualify for this policy, you must be affiliated with a CrossFit studio, if you are no longer affiliated with CrossFit you do not qualify for this policy, in order to qualify for a personal trainer policy, you must have a full Personal Trainer certificate of training."}

        <YesNoQuestion
          id={"crossfit.cf1"}
          path={"crossfit.cf1"}
          question={"If your CF1 Certification is over 2 years old, have you renewed your CF1 Certification?"}
        />
      </Card>
    );
  }
}
