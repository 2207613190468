import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { Select } from "@material-ui/core";

import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion,
} from "../helpers";
import { pricing } from "constants/pricing";

export default class Physiotherapy extends React.Component {
  render() {
    const { values, visible } = this.props;

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].physiotherapyPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].physiotherapyPrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Physiotherapy
        </Typography>
        <hr />
        <UploadQuestion
          fileCollectionName={"physiotherapyFiles"}
          question={
            "Please upload your certificate of training or provide your college registration number"
          }
        />
        <TextQuestion
          id={"physiotherapy.registration"}
          path={"physiotherapy.registration"}
          question={"Registration Number"}
        />

        <MultiSelectQuestion
          question={"Choose one of the following options:"}
          id={"physiotherapy.selection"}
          path={"physiotherapy.selection"}
          options={[
            {
              option: "$" + thisPricing.option1.toFixed(2) + " Annually for $2,000,000 Professional Liability",
              value: "option1",
            },

            {
              option:
                "$" + thisPricing.option2.toFixed(2) + " Annually for $2,000,000 Professional Liability and Commercial General Liability",
              value: "option2",
            },
            {
              option: "$" + thisPricing.option3.toFixed(2) + " Annually for $5,000,000 Professional Liability",
              value: "option3",
            },

            {
              option:
                "$" + thisPricing.option4.toFixed(2) + " Annually for $5,000,000 Professional Liability and Commercial General Liability",
              value: "option4",
            },
          ]}
        />
         <YesNoQuestion
          id={"physiotherapy.laser"}
          path={"physiotherapy.laser"}
          question={"Do you offer any type of Laser therapy (Use a Laser machine)?"}
        />
      { values.physiotherapy.laser === "yes"
        ? (<YesNoQuestion
          id={"physiotherapy.coldOrLowLevelLasers"}
          path={"physiotherapy.coldOrLowLevelLasers"}
          question={
          "Is this a Cold lasers or low-level lasers machine?"
          }
        />)
        : null}
        { values.physiotherapy.coldOrLowLevelLasers === "no"
        ? (<YesNoQuestion
          id={"physiotherapy.ownLaserMachine"}
          path={"physiotherapy.ownLaserMachine"}
          question={
          "Do you own the Laser machine?"
          }
        />)
        : null}
        { values.physiotherapy.ownLaserMachine === "yes"
            ? (
            <>
            <TextQuestion
                id={"physiotherapy.laserMachineDetails"}
                path={"physiotherapy.laserMachineDetails"}
                question={
                "Please provide us with the Type of laser machine, make, model, S/N"
                }
            />
            <TextQuestion
                id={"physiotherapy.laserMachineAge"}
                path={"physiotherapy.laserMachineAge"}
                question={
                "Please provide us with the age of machine, where was it manufactured & value"
                }
            />
            <TextQuestion
                id={"physiotherapy.laserMachineMobile"}
                path={"physiotherapy.laserMachineMobile"}
                question={
                "Please advise if this laser equipment will be used in the clinic only or in different locations / mobile"
                }
            />
            <YesNoQuestion
                id={"physiotherapy.laserMachineWaivers"}
                path={"physiotherapy.laserMachineWaivers"}
                question={
                "Are waivers being signed by each participant?"
                }
            />
            <TextQuestion
                id={"physiotherapy.laserMachineMaintenance"}
                path={"physiotherapy.laserMachineMaintenance"}
                question={
                "What is the maintenance for this equipment?"
                }
            />
            <TextQuestion
                id={"physiotherapy.laserMachineLeasedOwned"}
                path={"physiotherapy.laserMachineLeasedOwned"}
                question={
                "Is the equipment leased or owned by you?"
                }
            />
            <TextQuestion
                id={"physiotherapy.laserMachineUse"}
                path={"physiotherapy.laserMachineUse"}
                question={
                "What is the machine used for?"
                }
            />
            <YesNoQuestion
                id={"physiotherapy.laserMachineUsedBy"}
                path={"physiotherapy.laserMachineUsedBy"}
                question={
                "Will there be other practitioners operating the equipment?"
                }
            />
            <YesNoQuestion
                id={"physiotherapy.laserMachineTraining"}
                path={"physiotherapy.laserMachineTraining"}
                question={
                "Are you properly trained for operations of this laser machine?"
                }
            />
            
            </>)
        : null}
        {values.physiotherapy.coldOrLowLevelLasers === "yes"
        ?  (<UploadQuestion
            question={"Please upload training certificate for the use of the machine"}
            fileCollectionName={"physiotherapyLaserMachineFiles"}
           />)
        :null}
      </Card>
    );
  }
}
