import Form from "./ApplicantInformationForm";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;

  return {
    visible: true,
    values: formReducer.values
  };
};

const mapDispatchToProps = dispatch => {
  return {
    displayGenericModal: message => {
      dispatch({ type: "GENERIC_MODAL", message });
    },
    clearRMTBCON: message => {
      dispatch({ type: "HANDLE_RMT_BC_ON", message });
    }
  };
};

const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form);

export default ConnectedForm;
