import Form from "./Pilates";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;
  return {
    visible:
      formReducer.values.applicantInformation.modalities.indexOf(
        "Pilates"
      ) >= 0,
    values: formReducer.values
  };
};

const ConnectedForm = connect(
  mapStateToProps,
  null
)(Form);

export default ConnectedForm;
