import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import Agreement from "components/Agreement";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import Payment from "components/Payment";
import PaymentButton from "components/PaymentButton";
import ImageGallery from "components/ImageGallery";

import { YesNoQuestion, AgreementQuestion } from "../helpers";

const Disclaimer = props => {
        const values = props.values;
        const rtb =
          props.rtb ||
          values["applicantInformation"].newPolicyyes_no === "yes" ||
          values["applicantInformation"].exisitngPolicyyes_no === "no";
        return (
          <Card>
            <br />
            <Typography
              variant={"h5"}
              style={{
                flex: 1,
                color: "#22292f"
              }}
            >
              Disclaimer
            </Typography>
            <hr />
            <br />
            <YesNoQuestion
              id={"disclaimers.previousDecline"}
              path={"disclaimers.previousDecline"}
              question={
                "Has complimentary healthcare insurance ever been declined, cancelled or renewal thereof been refused by the insurer?"
              }
            />
            <br />
            <YesNoQuestion
              id={"disclaimers.lossesOrClaims"}
              path={"disclaimers.lossesOrClaims"}
              question={
                "Have you had any losses / claims in the past three years?"
              }
            />
            <br />
            <YesNoQuestion
              id={"disclaimers.knowledge"}
              path={"disclaimers.knowledge"}
              question={
                "Have you had any current or past circumstance that could result in you placing a claim or having a lawsuit being brought against you?*"
              }
            />
            <br />
            <br />
            <Typography variant={"h5"}>
              {" "}
              Notice Concerning Personal Information
            </Typography>
            <hr />
            <Row>
              <Col xs={12}>
                <div>
                  <Typography variant={"subtitle1"} align={"justify"}>
                    {" "}
                    <span style={{ textAlign: "justify", color:"#444444" }}>
                      I hereby consent to BrokerLink to collect,
                      use and disclose personal information required for the
                      purposes of considering my application for insurance for
                      new or renewal insurance coverage. The Broker is
                      authorized to collect, use and disclose personal
                      information and provide such personal information to third
                      parties, as required, including insurance companies. The
                      Broker may also be required to disclose such personal
                      information pursuant to relevant laws or other laws. I
                      authorize BrokerLink to communicate
                      directly with the member association.
                    </span>
                  </Typography>
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <Typography variant={"h5"}> Warranty Statement</Typography>
            <hr />
            <Row>
              <Col>
                <div>
                  <Typography variant={"subtitle1"} align={"justify"} style={{ color:"#444444"}}>
                    {" "}
                    By submitting this application, you attest that the
                    application has been completed accurately and honestly. No
                    disciplinary action has been taken or is pending against
                    you. You have never been the subject of any investigation,
                    either civil or criminal, in connection with any sexual act,
                    conduct, molestation, and/or assault. You understand that
                    your insurance certificate will provide evidence that you
                    have been added as an individual participant with respect to
                    the coverage and limits of the Master Policy. You understand
                    that the coverage provided by your insurance certificate is
                    subject to all the terms, conditions and exclusions
                    contained in the Master Policy. You further understand that
                    the Insurance Company will rely on the information you have
                    provided in the application. You are also able to provide a
                    certificate of training for the modality you practice on
                    your policy upon request. Failure to pay required premiums
                    and/or false statements on this application or subsequent
                    renewals shall void this application and render your
                    insurance coverage null and void, and you may be subject to
                    further legal action if making false statements.
                  </Typography>
                </div>
              </Col>
            </Row>
            <br />
            <AgreementQuestion
              id={"disclaimers.agreement"}
              path={"disclaimers.agreement"}
              question={"Please indicate your agreement:*"}
            />

            <br />
            <br />
            <strong style={{color:"444444"}}>Please review your answers before submitting, once your application/renewal is received a broker will review and contact you if further information is required, if all information is complete and your application/renewal is approved, your certificate of insurance will be emailed to the email provided, please make sure to check your spam/junk mail. Please Note this process can take <u>3-5 business days</u> to complete.</strong>
            <br />
            <strong style={{color:"444444"}}>Please note: Pricing is not guaranteed. Once application is submitted, it will be processed by the Brokerlink team</strong>
            <br />
            <br />
            <br />
            {rtb ? (
              ""
            ) : (
              <Payment
                id={"paymentOption.selection"}
                path={"paymentOption.selection"}
              />
            )}

            <PaymentButton />

            <ImageGallery />

            <br />
            <br />
          </Card>
        );
      };;
export default Disclaimer;
