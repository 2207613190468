import React from "react";
import CurrencyInput from "./CurrencyInput";
import { connect } from "react-redux";
import { getValue } from "../../utils";

const mapStateToProps = (state, ownProps) => {
  const { formReducer } = state;
  const valid = getValue(formReducer.valid, ownProps.path);

  return {
    error: {
      value: formReducer.submissionAttempted && !valid,
      message: "* This field is required"
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (path, value) =>
      dispatch({ type: "UPDATE_FORM", path, value }),
    handleMount: () => dispatch({ type: "MOUNTED", path: ownProps.path }),
    handleUnmount: () => dispatch({ type: "UNMOUNTED", path: ownProps.path })
  };
};

const ConnectedCurrencyInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrencyInput);

export default ConnectedCurrencyInput;
