import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion,
  InternationalClienteleQuestion,
  InternationalConsultingChargeQuestion,
  DateQuestionSmall
} from "../helpers";
import { pricing } from "constants/pricing";

export default class YogaForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].yogaPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].yogaPrices;
          break;
        }
      }
    }

    if (values.yoga.suspension === "yes") {
      this.props.displayGenericModal(
        "Unfortunately, our program does not allow uses a harness\
        or silks to suspend from the ground e.g. Aerial yoga, we\
        are happy to find an alternative policy for you. Please\
        complete the form and one of our Complementary Health\
        Brokers will contact you."
      );
      this.props.clearForm();
    }
    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Yoga
        </Typography>
        <hr />
        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                {" "}
                <strong> Do you offer a Yoga Teacher Training program?</strong>
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id="yoga.teacher" path="yoga.teacher" />
          </Col>
        </Row>
        {values.yoga.teacher === "yes" ? (
          <>
            {/* Q1 YogaTeacher */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    {" "}
                    <strong> Are you RYT500 certified?</strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.RYT500" path="yoga.RYT500" />
              </Col>
            </Row>
            {/* Q2 YogaTeacher */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    {" "}
                    <strong>
                      {" "}
                      Is the Yoga teacher program offered through a Studio?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.studioyesNo" path="yoga.studioyesNo" />
              </Col>
            </Row>
            {values.yoga.studioyesNo === "yes" ? (
              <>
                <Row>
                  <Col xs={12} sm={6}>
                    <div>
                      <Typography variant={"subtitle1"} align={"left"}>
                        <strong>
                          {" "}
                          Please provide us with the Studio name you certify
                          others from{" "}
                        </strong>
                      </Typography>
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <TextInput id="yoga.studioname" path="yoga.studioname" />
                  </Col>
                </Row>
              </>
            ) : (
                ""
              )}
          </>
        ) : (
            ""
          )}
          <Row>
          <Col xs={12} sm={6}>
          <div>
              {/* TRX changes */}
              <Typography variant={"subtitle1"} align={"left"}>
                {" "}
                <strong>
                  {" "}
                  Do you use TRX or Ropes in your practice?
                </strong>
              </Typography>
          </div>
          </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.trx" path="yoga.trx" />
          </Col>

              {
                values.yoga.trx === "yes"
                ? (
                  <>
                    <div>IMPORTANT EXCLUSION: Please note this policy prohibits full body suspension.  If you wish to proceed please confirm you do not allow clients to be fully suspended while using the ropes or TRX and you understand that in the event of an accident due to full body suspension, there is no coverage under this policy.</div>
                    <YesNoQuestion
                      id={"yoga.trxSuspension"}
                      path={"yoga.trxSuspension"}
                      question={"Do you wish to proceed?"}
                    />
                    {values.yoga.trxSuspension==="no" 
                      ? "Unfortunately, our program does not allow full body suspension, we are happy to find an alternative policy for you. Please complete the form and one of our Complementary Health Brokers will contact you."
                      : null}
                  </>
              )
              : null
            }
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                {" "}
                <strong>
                  {" "}
                  Do you teach yoga that uses a harness or silks to suspend from
                  the ground (eg. Aerial yoga)?
                </strong>
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id="yoga.suspension" path="yoga.suspension" />
          </Col>
        </Row>
        {/* ModoYoga */}

        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                {" "}
                <strong> Were you certified through or do you currently teach at a Modo Yoga Studio?</strong>
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id="yoga.modo" path="yoga.modo" />
          </Col>
        </Row>

        {/* Yyoga */}

        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                <strong> Were you certified through or do you currently teach at a Yyoga Studio?</strong>
              </Typography>
              <Typography variant={"caption"}>
                Please note this is a designated YYOGA Studio not a regular Yoga Studio
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id="yoga.yyoga" path="yoga.yyoga" />
          </Col>
        </Row>
        {/* power yoga */}

        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                <strong> Were you certified through or do you currently teach at a Power Yoga Canada Studio?</strong>
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id="yoga.power" path="yoga.power" />
          </Col>
        </Row>

        {/* kids yoga */}
        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                <strong> Do you teach Kids yoga?</strong>
              </Typography>

              <Typography variant={"caption"}>
                <strong>
                  Please be advised the following restrictions apply
                </strong>
                <br />
                <ul>
                  <li>
                    Parents/guardians must be outside the class or an adult must be in the class with instructor
                  </li>
                  <li>
                    Signed waivers required
                  </li>
                  <li>
                    Maximum 10 kids per class
                  </li>
                  <li>
                    Classes no longer than 60 minutes
                  </li>
                </ul>

                <strong>**These restrictions are waived if the class is being held in a school</strong>
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id="yoga.kidsyoga" path="yoga.kidsyoga" />
          </Col>
        </Row>

        {values.yoga.kidsyoga === "yes" ? (
          <UploadQuestion
            question="Please upload your Kids Yoga certificate of training"
            fileCollectionName="kidsyoga"
          />
        ) : (
            ""
          )}

        <br />
        <br />
        <Typography variant={"h6"}> Optional coverage</Typography>
        <br />

        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                {" "}
                <strong>
                  {" "}
                  Have you written any books or e-books that were either
                  published or self published?
                </strong>
              </Typography>
              <Typography variant="caption">
                Note: Downloadable books/e-books posted on your website, whether
                for a cost or free should be declared.
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id="yoga.ebookyesNo" path="yoga.ebookyesNo" />
          </Col>
        </Row>
        {values.yoga.ebookyesNo === "yes" ? (
          <AdditionalCoverageQuestion
            question={"Publisher’s Liability coverage provides the following for an additional $" + thisPricing.ebook.toFixed(2) + " annually. If you have an incidental publishing, such as writing articles in published magazines or online, written a book that has been either published in paper or as an E-book, you should consider Publisher’s liability extension. Whether your e-book is free on your website or you sell them, your exposure is much higher and the individual policy does not cover published writings. Publisher’s liability gives you $25,000 in coverage should someone decide to sue you based on something you published.   Please select to add to your policy."}
            id="yoga.ebookaddCoverage"
            value={values.yoga.ebookaddCoverage}
            info={
              "Publisher’s Liability coverage provides the following for an additional $" + thisPricing.ebook.toFixed(2) + " annually. If you have an incidental publishing, such as writing articles in published magazines or online, written a book that has been either published in paper or as an E-book, you should consider Publisher’s liability extension. Whether your e-book is free on your website or you sell them, your exposure is much higher and the individual policy does not cover published writings. Publisher’s liability gives you $25,000 in coverage should someone decide to sue you based on something you published. Please select to add to your policy."
            }
            coverageName="Publisher’s Liability"
            price={thisPricing.ebook}
          />
        ) : (
            ""
          )}

        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                {" "}
                <strong>
                  {" "}
                  Does your instruction include Stand Up Paddleboard
                  Yoga/fitness?
                </strong>
                {/* <p>Note: Downloadable books/e-books posted on your website, whether for a cost or free should be declared.</p> */}
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput
              id="yoga.paddleboardyesNo"
              path="yoga.paddleboardyesNo"
            />
          </Col>
        </Row>

        {values.yoga.paddleboardyesNo === "yes" ? (
          <>
            <Col xs={12} sm={12}>
              <Typography variant={"h6"}> Paddleboard Coverage </Typography>
            </Col>
            {/* Paddleboard q1 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong> Are you currently certified in CPR? </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.paddleboardq1" path="yoga.paddleboardq1" />
              </Col>
            </Row>

            {/* Paddleboard q2 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Do you have a water safety or life saving society
                      certificate?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.paddleboardq2" path="yoga.paddleboardq2" />
              </Col>
            </Row>

            {/* Paddleboard q3 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      What is the maximum number of students allowed in one
                      class?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <TextInput id="yoga.paddleboardq3" path="yoga.paddleboardq3" />
              </Col>
            </Row>

            {/* Paddleboard q4 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Are introductory or first time lessons on shore?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.paddleboardq4" path="yoga.paddleboardq4" />
              </Col>
            </Row>

            {/* Paddleboard q5 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Are life jackets worn by every student, including you?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.paddleboardq5" path="yoga.paddleboardq5" />
              </Col>
            </Row>

            {/* Paddleboard q6 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong> Do first time users where life jackets? </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.paddleboardq6" path="yoga.paddleboardq6" />
              </Col>
            </Row>

            {/* Paddleboard q7 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      If some students are not wearing a life jacket are the
                      life jackets on the board or close by?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.paddleboardq7" path="yoga.paddleboardq7" />
              </Col>
            </Row>

            {/* Paddleboard q8 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Are leashes used while students are on the boards?{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.paddleboardq8" path="yoga.paddleboardq8" />
              </Col>
            </Row>

            {/* Paddleboard q9 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Is the maximum depth of the water where lessons are held
                      more than 10 feet deep?
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.paddleboardq9" path="yoga.paddleboardq9" />
              </Col>
            </Row>

            {/* Paddleboard q10 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong> How far off shore do you practice? </strong>
                  </Typography>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <TextInput
                  id="yoga.paddleboardq10"
                  path="yoga.paddleboardq10"
                />
              </Col>
            </Row>

            {/* Paddleboard q11 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong> Do you use board anchors?</strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput
                  id="yoga.paddleboardq11"
                  path="yoga.paddleboardq11"
                />
              </Col>
            </Row>

            {/* Paddleboard q12 */}

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Other details regarding Stand Up Paddleboard Yoga you
                      would like to share:{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <TextInput
                  id="yoga.paddleboardq12"
                  path="yoga.paddleboardq12"
                />
              </Col>
            </Row>
          </>
        ) : (
            ""
          )}

        {values.yoga.paddleboardyesNo === "yes" ? (
          <AdditionalCoverageQuestion
            coverageName=" Stand Up Paddleboard Yoga"
            id="yoga.paddleboardaddCoverage"
            value={values.yoga.paddleboardaddCoverage}
            info={
              "To add coverage for Stand Up Paddleboard  to your policy, the additional premium will be $" + thisPricing.paddleboard.toFixed(2) + " annually"
            }
            price={thisPricing.paddleboard}
          />
        ) : (
            ""
          )}

        {/* Yoga Retreat */}
        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                <strong>
                  {" "}
                  Do you offer Yoga Retreats or events?{" "}
                </strong>
              </Typography>
              <Typography variant="caption">
                Note: Please fill out the following questions that the insurance company will need answered in order to consider extending coverage to your retreat or event.
                Please note Yoga Retreats and events are not automatically covered under this policy and an additional premium may apply
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id="yoga.retreatyesNo" path="yoga.retreatyesNo" />
          </Col>
        </Row>
        {values.yoga.retreatyesNo === "yes"?(
            <>
            <Col xs={12} sm={12}>
              <Typography variant={"h6"}> Retreat/Events Coverage </Typography>
            </Col>
            {/* Retreat q1 */}
            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong> What is the location of the retreat/event? </strong>
                  </Typography>
                  <Typography variant="caption">
                    Please enter full address
                </Typography>
                </div>
              </Col>
              <Col sm={3} xs={12}>
              <TextInput id="yoga.retreat1" path="yoga.retreat1" />
              </Col>
            </Row>
            <Row>
              <DateQuestionSmall 
                question={"What is the start date of retreat/event?"}
                id={"yoga.retreat2"}
                path={"yoga.retreat2"} />
            </Row>
            <Row>
              <DateQuestionSmall 
                question={"What is the end date of retreat/event?"}
                id={"yoga.retreat3"}
                path={"yoga.retreat3"} />
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                <div>
                <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                    {" "}
                    Are you the facilitator and/or organizer?{" "}
                    </strong>
                </Typography>
                </div>
            </Col>
            <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.retreat4" path="yoga.retreat4" />
            </Col>
            </Row>
            {values.yoga.retreat4 === "no"? (
                <>
                <Row>
                    <Col xs={12} sm={6}>
                        <div>
                        <Typography variant={"subtitle1"} align={"left"}>
                            <strong>If not, who is organizing this event?</strong>
                        </Typography>
                        </div>
                        </Col>
                        <Col xs={12} sm={3}>
                        <TextInput
                        id="yoga.retreat5"
                        path="yoga.retreat5"
                        />
                    </Col>
                </Row>
                </>
            ):("")}
            <Row>
                <Col xs={12} sm={6}>
                    <div>
                        <Typography variant={"subtitle1"} align={"left"}>
                            <strong>Number of participants expected to attend:</strong>
                        </Typography>
                    </div>
                    </Col>
                    <Col sm={3} xs={12}>
                    <TextInput
                    id="yoga.retreat6"
                    path="yoga.retreat6"
                    />
                </Col>
            </Row>
            <Row>
            <Col xs={12} sm={6}>
            <div>
                <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                    {" "}
                    Are all of the participants Canadian?{" "}
                    </strong>
                </Typography>
            </div>
            </Col>
            <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.retreat7" path="yoga.retreat7" />
            </Col>
        </Row>
        <Row>
            <Col xs={12} sm={6}>
                <div>
                <Typography variant={"subtitle1"} align={"left"}>
                    <strong>List the activities that will be offered at this event:</strong>
                </Typography>
                </div>
                </Col>
                <Col xs={12} sm={3}>
                <TextInput
                    id="yoga.retreat8"
                    path="yoga.retreat8"
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} sm={6}>
                <div>
                <Typography variant={"subtitle1"} align={"left"}>
                    <strong>Of these activities, which are you responsible for facilitating?</strong>
                </Typography>
                </div>
                </Col>
                <Col xs={12} sm={3}>
                <TextInput
                    id="yoga.retreat9"
                    path="yoga.retreat9"
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} sm={6}>
                <div>
                <Typography variant={"subtitle1"} align={"left"}>
                    <strong>How many instructors/practitioners will be attending?</strong>
                </Typography>
                </div>
                </Col>
                <Col xs={12} sm={3}>
                <TextInput
                    id="yoga.retreat10"
                    path="yoga.retreat10"
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} sm={6}>
            <div>
                <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                    {" "}
                    Do all instructors/practitioners attending have their own insurance?{" "}
                    </strong>
                </Typography>
            </div>
            </Col>
            <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.retreat11" path="yoga.retreat11" />
            </Col>
        </Row>
        <Row>
            <Col xs={12} sm={6}>
            <div>
                <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                    {" "}
                    Will the resort/retreat facility be providing and preparing all the food?{" "}
                    </strong>
                </Typography>
            </div>
            </Col>
            <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.retreat12" path="yoga.retreat12" />
            </Col>
        </Row>
        {values.yoga.retreat12==="no"?(
                <Row>
                    <Col xs={12} sm={6}>
                        <div>
                        <Typography variant={"subtitle1"} align={"left"}>
                            <strong>If not, who will be providing and preparing the food?</strong>
                        </Typography>
                        </div>
                        </Col>
                        <Col xs={12} sm={3}>
                        <TextInput
                        id="yoga.retreat13"
                        path="yoga.retreat13"
                        />
                    </Col>
        </Row>):("")}
        <Row>
            <Col xs={12} sm={6}>
            <div>
                <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                    {" "}
                    Will you be responsible for making the travel arrangements for the participants?{" "}
                    </strong>
                </Typography>
            </div>
            </Col>
            <Col sm={3} xs={12} className="yesNoGroup">
                <YesNoInput id="yoga.retreat14" path="yoga.retreat14" />
            </Col>
        </Row>
        {values.yoga.retreat14==="no"?(
                <Row>
                    <Col xs={12} sm={6}>
                        <div>
                        <Typography variant={"subtitle1"} align={"left"}>
                            <strong>If not, who is responsible for the travel arrangements?</strong>
                        </Typography>
                        </div>
                        </Col>
                        <Col xs={12} sm={3}>
                        <TextInput
                        id="yoga.retreat15"
                        path="yoga.retreat15"
                        />
                    </Col>
        </Row>):("")}
        <Row>
            <Col xs={12} sm={6}>
                <div>
                <Typography variant={"subtitle1"} align={"left"}>
                    <strong>What first aid precautions will be in place?</strong>
                </Typography>
                </div>
                </Col>
                <Col xs={12} sm={3}>
                <TextInput
                id="yoga.retreat16"
                path="yoga.retreat16"
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} sm={6}>
                <div>
                <Typography variant={"subtitle1"} align={"left"}>
                    <strong>How have you been advertising for this retreat/event?</strong>
                </Typography>
                <Typography variant="caption">
                    Please provide a link
                </Typography>
                </div>
                </Col>
                <Col xs={12} sm={3}>
                <TextInput
                id="yoga.retreat17"
                path="yoga.retreat17"
                />
            </Col>
        </Row>         
        </>
        ):("")}


        {/* Yoga Therapy */}
        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                <strong>
                  {" "}
                  Are you certified as a Yoga Therapist and have completed 800
                  hours of training?{" "}
                </strong>
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id="yoga.therapyyesNo" path="yoga.therapyyesNo" />
          </Col>
        </Row>

        <br />

        {values.yoga.therapyyesNo === "yes" ? (
          <>
            <AdditionalCoverageQuestion
              coverageName=" Yoga Therapy"
              id="yoga.therapyaddCoverage"
              info={
                "To add coverage for Yoga Therapy to your policy, the additional premium will be $" + thisPricing.therapy.toFixed(2) + " annually"
              }
              price={thisPricing.therapy}
            />

            <Row>
              <Col xs={12} sm={6}>
                <div>
                  <Typography variant={"subtitle1"} align={"left"}>
                    <strong>
                      {" "}
                      Please upload your certificate of training{" "}
                    </strong>
                  </Typography>
                </div>
              </Col>
              <Col sm={6} xs={12} className="yesNoGroup">
                <FileUploadInput fileCollectionName="yogaFiles" />
              </Col>
            </Row>
          </>
        ) : (
            ""
          )}

        <Row>
          <Col xs={12} sm={6}>
            <div>
              <Typography variant={"subtitle1"} align={"left"}>
                <strong>
                  {" "}
                  Do you consult or offer programs with Clients via the
                  internet?
                </strong>
              </Typography>
              <Typography variant="caption">
                If you consult with clients via the internet (website, social
                media), international Telephone call, Skype, or Face Time you
                may purchase coverage for International Web Based Consulting
              </Typography>
            </div>
          </Col>
          <Col sm={3} xs={12} className="yesNoGroup">
            <YesNoInput id="yoga.consultingyesNo" path="yoga.consultingyesNo" />
          </Col>
        </Row>
        {values.yoga.consultingyesNo === "yes" ? (
          <>
            <AdditionalCoverageQuestion
              coverageName="International Web based extension"
              id="yoga.consultingaddCoverage"
              value={values.yoga.consultingaddCoverage}
              type="international"
              info={
                  "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
                advise, or information, where you cannot control whether it’s a client within Canada or outside of\
                Canada, viewing your advice or content.\
                If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
                Please consider adding the International Web Based Extension to your policy.\
                Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
                must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
                client prior to a session."
              }
              price={thisPricing.consulting}
            />
            <InternationalClienteleQuestion
                id="yoga.consultingClientele"
                type="international"
            />
            { values.yoga.consultingClientele === "yes" ?
                <strong>Please complete the form and one of our Complementary Health Brokers will contact you.</strong>
                : <InternationalConsultingChargeQuestion
                    id="yoga.consultingCharge"
                 />
            }
          </>
        ) : (
            ""
          )}
      </Card>
    );
  }
}
