import React, { useState, useEffect } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import isEqual from "lodash";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import styles from "./Agreement.module.css";

const Agreement = props => {
  const { id, handleChange, error, handleMount, handleUnmount } = props;
  const [value, setValue] = useState(props.value);
  const isError = error && error.value === true;

  useEffect(() => {
    handleMount();
    return () => {
      handleUnmount();
    };
  }, []);

  return (
    <>
      <RadioGroup
        id={id}
        aria-label="yesno"
        name={id}
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          backgroundColor: !isError ? "white" : "rgb(244, 67, 54, 0.1)",
          borderRadius: 10,
          paddingBottom: 5,
          paddingTop: 5,
          marginTop: 15
        }}
        onChange={e => {
          setValue(e.target.value);
          handleChange(id, e.target.value);
        }}
        value={value}
      >
        <FormControlLabel
          value="yes"
          control={<Radio color="default" />}
          label={<FormLabel>I agree </FormLabel>}
        />
        <FormControlLabel
          value="no"
          control={<Radio color="default" />}
          label={
            <FormLabel>
              I do <span style={{ color: "#22292f" }}> NOT</span> agree{" "}
            </FormLabel>
          }
        />
      </RadioGroup>
      {isError ? (
        <div style={{ flex: "1 0 50%" }}>
          <span className={styles.helpText}>{error.message}</span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

Agreement.defaultProps = {
  handleChange: () => {},
  handleUnmount: () => {},
  handleMount: () => {}
};

export default Agreement;
