import React from "react";
import ImageGallery from "./ImageGallery";
import { connect } from "react-redux";
import { getValue } from "../../utils";

const mapStateToProps = (state, ownProps) => {
  const { validationReducer, formReducer, visibilityReducer } = state;

  return {
    values: formReducer.values
  };
};

const ConnectedImageGallery = connect(
  mapStateToProps,
  null
)(ImageGallery);

export default ConnectedImageGallery;
