import React from "react";
import Card from "components/Card";
import Typography from "@material-ui/core/Typography";
import {
  AdditionalCoverageQuestion,
  YesNoQuestion,
  InternationalClienteleQuestion,
  InternationalConsultingChargeQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

export default class ReikiForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length - 1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].reiki;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].reiki;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Reiki
        </Typography>
        <hr />
        <br />
        <Typography variant={"h6"}> Optional coverage</Typography>
        <br />
        <YesNoQuestion
          id={"reiki.consultingyesNo"}
          path={"reiki.consultingyesNo"}
          question={
            "Do you consult or offer programs with Clients via the internet?"
          }
          info="If you consult with clients via the internet (i.e. website, social media), international telephone call, Skype or FaceTime, you may purchase coverage for International Web-Based consulting"
        />
        {values.reiki.consultingyesNo === "yes" ? (
            <>
          <AdditionalCoverageQuestion
                    coverageName="International Web based extension"
                    type="international"
            id="reiki.consultingaddCoverage"
            path={"reiki.consultingaddCoverage"}
            info={
                "If you have an international exposure, where you are posting videos, blogs, have a website that contains\
                advise, or information, where you cannot control whether it’s a client within Canada or outside of\
                Canada, viewing your advice or content.\
                If you consult via Facebook, Zoom, Skype with clients outside of Canada.\
                Please consider adding the International Web Based Extension to your policy.\
                Please note this extension only allows 50% of your receipts to be from clients outside of Canada, Suites\
                must be brought back to Canada and waivers and a disclaimer must be provided on your website or to a\
                client prior to a session."
            }
            price={thisPricing.consulting}
          />
                <InternationalClienteleQuestion
                    id="reiki.consultingClientele"
                    type="international"
                />
                { values.reiki.consultingClientele === "yes" ?
                        <strong>Please complete the form and one of our Complementary Health Brokers will contact you.</strong>
                        :<InternationalConsultingChargeQuestion
                        id="reiki.consultingCharge"
                        />
                }
          </>
        ) : (
            ""
          )}
      </Card>
    );
  }
}
