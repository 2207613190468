import React from "react";
import Card from "components/Card";
import Row from "components/Row";
import Col from "components/Col";
import TextInput from "components/TextInput";
import Question from "components/Question";
import YesNoInput from "components/YesNoInput";
import DateInput from "components/DateInput";
import Autocomplete from "components/Autocomplete";
import FileUploadInput from "components/FileUploadInput";
import Typography from "@material-ui/core/Typography";
import NumberInput from "components/NumberInput";
import SelectInput from "components/SelectInput";
import { Select } from "@material-ui/core";
import { Link } from "@material-ui/core";

import {
  AutocompleteQuestion,
  MultiSelectQuestion,
  DateQuestion,
  NumberQuestion,
  AdditionalCoverageQuestion,
  UploadQuestion,
  YesNoQuestion,
  TextQuestion
} from "../helpers";
import { pricing } from "constants/pricing";

const Redirect = props => {
  const { apply, renew } = props;
  return (
    <div>
      <Typography variant={"subheading2"} align={"left"}>
        {" "}
        <strong>
          {" "}
          To continue with your application proceed to one of the links below{" "}
        </strong>
      </Typography>
      <ul>
        <li>
          {" "}
          <Link href={apply}>New Applicant</Link>
        </li>
        <li>
          {" "}
          <Link href={renew}>Renewal</Link>
        </li>
      </ul>
    </div>
  );
};

export default class ReflexologyForm extends React.Component {
  render() {
    const { values, visible } = this.props;

    // if (values.reflexology.RAC === "yes") {
    //   this.props.displayGenericModal(
    //     <Redirect
    //       apply={"https://reflexologyinsurance.com/rac/apply/"}
    //       renew={"https://reflexologyinsurance.com/rac/renew/"}
    //     />
    //   );
    //   this.props.clearForm();
    // }

    // determine if the pricing is for new business or old, and what the effective date is
    let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
    if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate != "") {
      effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
    }
    let thisPricing;

    // knowing the effective date, go backwards through the pricing array to find the pricing that matches
    // pricing in array is epoch time, so multiply by 1000 to get javascript version
    for (let i = pricing.length-1; i >= 0; i--) {
      if (values.applicantInformation.newPolicyyes_no === "yes") {
        if (effectiveDate > pricing[i].effective.new * 1000) {
          thisPricing = pricing[i].reflexologyPrices;
          break;
        }
      } else {
        if (effectiveDate > pricing[i].effective.renewal * 1000) {
          thisPricing = pricing[i].reflexologyPrices;
          break;
        }
      }
    }

    return !visible ? null : (
      <Card style={{ marginTop: "20px" }}>
        <Typography variant={"h5"} style={{ color: "#22292f" }}>
          Reflexology
        </Typography>
        <hr />
        <YesNoQuestion
          id={"reflexology.RAC"}
          path={"reflexology.RAC"}
          question={
            "Are you a member of the Reflexology Association of Canada?"
          }
        />

        {values.reflexology.RAC === "yes" ? (
          <MultiSelectQuestion
            id={"reflexology.RACPolicy"}
            path={"reflexology.RACPolicy"}
            question={"Please choose an RAC coverage option"}
            options={[
              {
                option: " RAC $2,000,000 coverage Policy - Price $" + thisPricing.RACPrices.RACOption1.toFixed(2),
                value: "RACOption1"
              },
              {
                option: " RAC $5,000,000 coverage Policy - Price $" + thisPricing.RACPrices.RACOption2.toFixed(2),
                value: "RACOption2"
              }
            ]}
          />
        ) : null}

        <YesNoQuestion
          id={"reflexology.RRCO"}
          path={"reflexology.RRCO"}
          question={
            "Are you a member of RRCO – Reflexology Registration Council of Ontario?"
          }
        />

        <br />
        <YesNoQuestion
          id={"reflexology.certificationyesNo"}
          path={"reflexology.certificationyesNo"}
          question={
            "Do you offer a Certification program, where you are teaching and certifying others?"
          }
        />
        {values.reflexology.certificationyesNo === "yes" ? (
          <AdditionalCoverageQuestion
            coverageName="Certification"
            id="reflexology.certificationaddCoverage"
            path={"reflexology.certificationaddCoverage"}
            info={
              "Please Note: You must have a Minimum 3 years of experience in the modality you are teaching and The curriculum must be based on a pre-existing course program"
            }
            price={thisPricing.certification}
          />
        ) : (
          ""
        )}
      </Card>
    );
  }
}
