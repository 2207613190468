function getValue(object, path) {
  var original = "a " + path;
  try {
    var stack = path.split(".");

    while (stack.length > 1) {
      object = object[stack.shift()];
    }

    return object[stack.shift()];
  } catch (err) {
    console.log("object does not have value in given path", object, original);
    console.error("object does not have value in given path", object, original);
    return null;
  }
}

export default getValue;
